import { useThree, useLoader } from "@react-three/fiber";
import { TextureLoader } from 'three/src/loaders/TextureLoader'
import backgroundTexture from '../../src/assets/tool/viewer/background/bg.png'
function Scene() {
  function SkyBox() {
    const { scene } = useThree();
    const sceneTexture = useLoader(TextureLoader, backgroundTexture);
    // Set the scene background property to the resulting texture.
    scene.background = sceneTexture;
    return null;
  }
  //return <color attach="background" args={["#fff"]} />;
  return <SkyBox />
}

export default Scene;
