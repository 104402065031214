function Lightings() {
  return (
    <>
      <directionalLight
        color={"#fff"}
        intensity={2}
        position={[0, 10, 10]}
        castShadow={false}
        // shadow-bias={-0.005}
        // shadow-mapSize={[5000, 5000]}
        // shadow-camera-far={100}
        // shadow-camera-left={-150}
        // shadow-camera-right={150}
        // shadow-camera-top={150}
        // shadow-camera-bottom={-150}
      />

      <directionalLight
        color={"#fff"}
        intensity={2}
        position={[0, 10, -10]}
        castShadow={false}
        // shadow-bias={-0.005}
        // shadow-mapSize={[5000, 5000]}
        // shadow-camera-far={100}
        // shadow-camera-left={-150}
        // shadow-camera-right={150}
        // shadow-camera-top={150}
        // shadow-camera-bottom={-150}
      />

      <directionalLight
        color={"#fff"}
        intensity={2}
        position={[10, 2, 0]}
        castShadow={true}
        shadow-bias={-0.005}
        shadow-mapSize={[5000, 5000]}
        shadow-camera-far={100}
        shadow-camera-left={-150}
        shadow-camera-right={150}
        shadow-camera-top={150}
        shadow-camera-bottom={-150}
      />

      <directionalLight
        color={"#fff"}
        intensity={2}
        position={[-10, 2, 0]}
        castShadow={true}
        shadow-bias={-0.005}
        shadow-mapSize={[5000, 5000]}
        shadow-camera-far={100}
        shadow-camera-left={-150}
        shadow-camera-right={150}
        shadow-camera-top={150}
        shadow-camera-bottom={-150}
      />
    </>
  );
}

export default Lightings;
