import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import {
  Row,
  Col,
  Dropdown,
  Accordion,
  Modal,
  Form,
  FormControl,
  Tab,
  Nav,
} from "react-bootstrap";
import {
  TokenPriceChange_update_Action,
  Token_Get_Detials_Action,
  TokenCounts_Get_Detail_Action,
  PurchaseNow_Complete_Action,
  getNftByCat,
  getNftByCatName,
} from "../apiAction/nfts.js";
import { useSelector } from "react-redux";
import NFT1155 from "../ABI/NFT1155.json";
import config from "../config/index.js";
import { bignumber, log, multiply } from "mathjs";
import { connection } from "../../src/helper/connection.js";
import Web3 from "web3";
import BEP1155_ABI from "../../src/ABI/NFT1155.json";
import { toast } from "react-toastify";
import moment from "moment";
//toast.configure();

import PropagateLoader from "react-spinners/PropagateLoader";

const override = {
  margin: "0 auto",
  borderColor: "red",
  position: "fixed",
  top: "50%",
  left: "50%",
  marginLeft: "-32px",
  marginTop: "-32px",
  zIndex: "99999",
};

let toasterOption = config.toasterOption;

export default function Nftdetail(props) {
  var { tokenidval } = useParams();
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [TokenPrice, Set_TokenPrice] = useState("");
  const [tokenCounts, Set_tokenCounts] = useState(tokenidval);
  const [item, Set_item] = useState({});
  const [AllowedQuantity, Set_AllowedQuantity] = useState(0);
  const [MyTokenBalance, Set_MyTokenBalance] = useState(0);
  const [MyTokenDetail, Set_MyTokenDetail] = useState({});
  const walletConnection = useSelector((state) => state.walletConnection);
  const [YouWillPay, Set_YouWillPay] = useState(0);
  const [seller, setSeller] = useState("");
  const [PurchaseBalance, set_PurchaseBalance] = useState(0);
  const [available, setAvailable] = useState("");
  const [price, setPrice] = useState(0);
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [VQuattity, Set_VQuattity] = useState();
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [servicefee, Set_servicefee] = useState(0);
  const [FormSubmitLoading, Set_FormSubmitLoading] = useState(false);
  const [ValidateError, Set_ValidateError] = useState({});
  const [activity, setActivity] = useState();

  const [additionaldata, setAdditionalData] = useState([]);

  const [currentOwner, set_currentOwner] = useState({});

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleClose1 = () => setShow1(false);
  const handleShow1 = async (item, itemDetails) => {
    if (walletConnection.address) {
      let web3 = walletConnection.web3;
      await web3.eth.getBalance(walletConnection.address).then((val) => {
        var balance = val / 1e18;
        set_PurchaseBalance(balance);
      });
      set_currentOwner(itemDetails);
      setSeller(itemDetails.tokenOwner);
      setAvailable(itemDetails.balance);
      setPrice(itemDetails.price);
      setShow1(true);
    }
  };

  const handleClose2 = () => setShow2(false);
  const handleShow2 = () => setShow2(true);

  const handleClose3 = () => setShow3(false);
  const handleShow3 = () => setShow3(true);

  // useEffect(() => {
  //   getNftbyCat()
  // }, [])

  const getNftbyCat = async (id, type) => {
    try {
      let data = await getNftByCatName({ name: id, type: type });
      console.log(data, "data   cata");
      setAdditionalData(data.data.result);

      // console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getTokenOwnerInfo();
    TokenCounts_Get_Detail_Call();
    getServiceFee1();
  }, [walletConnection.address]);

  const getServiceFee1 = async () => {
    try {
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        var CoursetroContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        var fee = await CoursetroContract.methods.getServiceFee().call();
        Set_servicefee(fee);
      }
    } catch (err) {
      console.log(err);
    }
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  const TokenCounts_Get_Detail_Call = async () => {
    try {
      if (walletConnection.address) {
        var payload = {
          curAddr: walletConnection.address,
          tokenCounts: tokenidval,
        };
        var Resp = await TokenCounts_Get_Detail_Action(payload);
        console.log(Resp, "RespRespResp");
        if (
          Resp &&
          Resp &&
          Resp.data &&
          Resp.data.Detail &&
          Resp.data.Detail.Resp
        ) {
          var TokenResp = Resp.data.Detail.Resp;
          if (
            TokenResp &&
            TokenResp.Token &&
            TokenResp.Token[0] &&
            TokenResp.Token[0].tokenowners_current
          ) {
            for (
              let i = 0;
              i < TokenResp.Token[0].tokenowners_current.length;
              i++
            ) {
              const element = TokenResp.Token[0].tokenowners_current[i];
              if (
                element.balance > 0 &&
                //element.price > 0 &&
                element.tokenOwner != walletConnection.address
              ) {
                console.log(element, "elementelementelement");
                Set_BuyOwnerDetailFirst(element);
                break;
              }
            }
            let ageSum = 0;
            var tokenowners_all =
              TokenResp &&
              TokenResp.Token &&
              TokenResp.Token[0] &&
              TokenResp.Token[0].tokenowners_all
                ? TokenResp.Token[0].tokenowners_all
                : [];
            if (tokenowners_all && tokenowners_all.length > 0) {
              for (let i = 0; i < tokenowners_all.length; i++) {
                if (tokenowners_all[i].balance > 0) {
                  ageSum += tokenowners_all[i].balance;
                }
              }
            }
            var IndexVal = -1;
            if (TokenResp && TokenResp.Token.length > 0) {
              if (
                TokenResp.Token[0].tokenowners_all &&
                walletConnection.address
              ) {
                var tokenowners_all = TokenResp.Token[0].tokenowners_all;
                IndexVal = tokenowners_all.findIndex(
                  (val) =>
                    val.tokenOwner.toString() ==
                    walletConnection.address.toString()
                );
              }
            }
            var newMyTokenBalance = 0;
            if (IndexVal > -1) {
              newMyTokenBalance = tokenowners_all[IndexVal].balance;
              Set_MyTokenBalance(newMyTokenBalance);
              Set_MyTokenDetail(tokenowners_all[IndexVal]);
            } else {
              newMyTokenBalance = 0;
              Set_MyTokenBalance(0);
              Set_MyTokenDetail({});
            }
            if (ageSum) {
              Set_AllowedQuantity(ageSum - newMyTokenBalance);
            } else {
              Set_AllowedQuantity(0);
            }

            if (TokenResp.Token && TokenResp.Token[0]) {
              console.log(TokenResp, "TokenResp");
              Set_item(TokenResp.Token[0]);
            }
          }
          console.log(TokenResp, "TokenResp");
          if (TokenResp.Activity) {
            console.log(TokenResp.Activity, "sakthi bala");
            setActivity(TokenResp.Activity);
            await getNftbyCat(
              TokenResp.Token[0].category_name,
              TokenResp.Token[0].Nft_type
            );
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const getTokenOwnerInfo = async () => {
    try {
      if (walletConnection.address) {
        let tokenId = 1256346;
        const payload = {
          curAddr: walletConnection.address,
          tokenCounts: tokenId,
        };
        let Resp = await Token_Get_Detials_Action(payload);
        console.log(Resp, "RespRespResp");
        if (
          Resp &&
          Resp &&
          Resp.data &&
          Resp.data.Detail &&
          Resp.data.Detail.Resp
        ) {
          let TokenResp = Resp.data.Detail.Resp;
          if (
            TokenResp &&
            TokenResp.Token &&
            TokenResp.Token[0] &&
            TokenResp.Token[0].tokenowners_current
          ) {
            for (
              let i = 0;
              i < TokenResp.Token[0].tokenowners_current.length;
              i++
            ) {
              const element = TokenResp.Token[0].tokenowners_current[i];
              if (
                element.balance > 0 &&
                //element.price > 0 &&
                element.tokenOwner != walletConnection.address
              ) {
                console.log(element, "elementelementelement");
                Set_BuyOwnerDetailFirst(element);
                break;
              }
            }

            if (TokenResp.Token && TokenResp.Token[0]) {
              Set_item(TokenResp.Token[0]);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const PutOnSale_Click = async (item, BuyOwnerDetailFirst, flog) => {
    try {
      if (walletConnection.address) {
        console.log(item, "item.tokenCounts");

        if (TokenPrice == 0 || TokenPrice == "") {
          toast.error(`Price Must not be Empty or 0`, toasterOption);
          return false;
        }

        let web3 = walletConnection.web3;
        var multiNFTContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );

        console.log(TokenPrice, "TokenPriceTokenPrice");
        var newtoken = TokenPrice;
        var price = bignumber(newtoken);
        price = multiply(price, 10 ** 18);
        price = await convert(price);
        const getBalans = await multiNFTContract.methods
          .balanceOf(walletConnection.address, item.tokenCounts)
          .call();
        console.log(
          getBalans,
          "result",
          walletConnection.address.toLowerCase()
        );
        if (parseInt(getBalans) == 0) {
          alert("Currently you nothing have any nfts");
          setTimeout(() => {
            // window.location.reload();
          }, 1000);
          return false;
        }
        Set_FormSubmitLoading(true);

        await multiNFTContract.methods
          .orderPlace(item.tokenCounts, price.toString())
          .send({ from: walletConnection.address })
          .then(async (result) => {
            var postData = {
              tokenOwner: walletConnection.address,
              tokenCounts: item.tokenCounts,
              price: TokenPrice,
              // biddingtoken: biddingtoken,
              blockHash: result.blockHash,
              transactionHash: result.transactionHash,
              // TokenPrice_Initial: TokenPrice_Initial,
            };
            var Resp = await TokenPriceChange_update_Action(postData);
            if (
              Resp.data &&
              Resp.data.RetType &&
              Resp.data.RetType == "success"
            ) {
              Set_FormSubmitLoading(true);
              toast.success(`Successfully Completed`, toasterOption);

              setTimeout(() => {
                window.location.reload(false);
              }, 2000);
            }
          })
          .catch((err) => {
            Set_FormSubmitLoading(false);

            console.log(err);
          });
      }
    } catch (err) {
      Set_FormSubmitLoading(false);

      console.log(err);
    }
  };

  const CancelOrder_Click = async () => {
    try {
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        var multiNFTContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        const getBalans = await multiNFTContract.methods
          .balanceOf(walletConnection.address, item.tokenCounts)
          .call();
        if (parseInt(getBalans) == 0) {
          alert("Currently you nothing have any nfts");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }
        Set_FormSubmitLoading(true);

        multiNFTContract.methods
          .cancelOrder(item.tokenCounts)
          .send({ from: walletConnection.address })
          .then(async (result) => {
            var postData = {
              tokenOwner: walletConnection.address,
              tokenCounts: item.tokenCounts,
              tokenPrice: 0,
              // blockHash: result.transactionHash,
              transactionHash: result.transactionHash,
              BidToken: "ETH",
              from: "cancel",
            };
            var Resp = await TokenPriceChange_update_Action(postData);
            Set_FormSubmitLoading(false);

            if (
              Resp.data &&
              Resp.data.RetType &&
              Resp.data.RetType == "success"
            ) {
              toast.success(`Sale order cancelled successfully`, toasterOption);

              // alert("");
              setTimeout(() => {
                window.location.reload(false);
              }, 1500);
            }
          })
          .catch((err) => {
            Set_FormSubmitLoading(false);

            console.log(err);
          });
      }
    } catch (err) {
      Set_FormSubmitLoading(false);

      console.log(err);
    }
  };

  const InputChange = (e) => {
    var value = e.target.value;
    Set_NoOfToken(value);
    PriceCalculate({
      quantity: Number(value),
      PurchaseCurrency: "BNB",
    });
  };

  const InputChangeprice = (e) => {
    var value = e.target.value;
    Set_TokenPrice(value);
    PriceCalculate({
      price: Number(value),
      PurchaseCurrency: "BNB",
    });
  };

  async function ChecktokenDecimal(amount, decimals) {
    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
      decimalsLength = decimals;
    }
    try {
      var number = amount.toString();
      var number1 = number.split(".");
      if (number1[1] && number1[1].length && number1[1].length > 0) {
        result = number1[0];
      } else {
        result = amount;
      }
      return result;
    } catch (err) {
      return result;
    }
  }

  const PriceCalculate = async (data = {}) => {
    try {
      console.log(data, "dddd");
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        var CoursetroContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        var fee = await CoursetroContract.methods.getServiceFee().call();

        console.log(fee, "fee");

        var price = typeof data.price != "undefined" ? data.price : TokenPrice;

        var quantity =
          typeof data.quantity != "undefined" ? data.quantity : NoOfToken;

        console.log(data.quantity, NoOfToken, "NoOfTokenNoOfToken");

        // var newPrice2 = quantity * 1e18;

        // console.log(newPrice2)
        //
        var newPrice = quantity;
        // var newPrice = newPrice1;

        console.log(newPrice, quantity, "newPrice");
        var per = ((newPrice * fee) / 1e18) * 1e18;

        console.log(per, newPrice, fee, "newPrice 11");

        var mulWei = newPrice + per;

        mulWei = await convert(mulWei);
        mulWei = await ChecktokenDecimal(mulWei, config.decimalvalues);

        console.log(mulWei / config.decimalvalues, "mulWei");

        Set_YouWillPay((mulWei / config.decimalvalues).toFixed(config.toFixed));
      }
    } catch (err) {
      console.log(err);
    }
  };
  const halfAddrShow = (addr) => {
    if (addr) {
      return addr.substring(0, 5) + "...." + addr.slice(addr.length - 5);
    } else {
      return "";
    }
  };

  const ItemValidation = async (data = {}) => {
    var ValidateError = {};
    var Chk_TokenPrice = typeof data.price != "undefined" ? data.price : price;

    console.log(item, "itemitem");
    var quantity =
      typeof data.quantity != "undefined" ? data.quantity : NoOfToken;

    let Collectible_balance = 0;
    if (currentOwner && currentOwner.balance) {
      Collectible_balance = currentOwner.balance;
      console.log(item.tokenowners_current[0].balance, "item");
    }
    console.log(Collectible_balance, "Collectible_balance");

    if (item.type == 1155) {
      if (Math.sign(quantity) === -1) {
        ValidateError.NoOfToken = '"Quantity" must be greater than 0';
      }
      if (quantity == 0) {
        ValidateError.NoOfToken = '"Quantity" must be greater than 0';
      }
      if (isNaN(quantity) == true) {
        ValidateError.NoOfToken = '"Quantity" must be a number';
      }
      if (quantity == "") {
        ValidateError.NoOfToken = '"Quantity" is not allowed to be empty';
      }
      if (quantity > Collectible_balance) {
        ValidateError.NoOfToken =
          '"Quantity" must be below on ' + Collectible_balance;
      }
    }

    if (Chk_TokenPrice == "") {
      ValidateError.TokenPrice = '"Token Price" is not allowed to be empty';
    } else if (Chk_TokenPrice == 0) {
      ValidateError.TokenPrice = '"Token Price" must be greater than 0';
    } else if (isNaN(Chk_TokenPrice) == true) {
      ValidateError.TokenPrice = '"Token Price" must be a number';
    }
    // else if (parseFloat(YouWillPay) > parseFloat(PurchaseBalance)) {
    //   ValidateError.TokenPrice =
    //     "Insufficient balance, Check your wallet balance";
    // } else {
    //   // await props.GetUserBal();
    //   if (parseFloat(YouWillPay) > parseFloat(PurchaseBalance)) {
    //     ValidateError.TokenPrice =
    //       "Insufficient balance, Check your wallet balance";
    //   } else {
    //     delete ValidateError.TokenPrice;
    //   }
    // }
    Set_ValidateError(ValidateError);
    return ValidateError;
  };

  async function FormSubmit() {
    var errors = await ItemValidation();
    console.log(errors, "errors");
    var errorsSize = Object.keys(errors).length;
    if (errorsSize != 0) {
      // toast.error(
      //   "Form validation error, please fill all the required fields",
      //   toasterOption
      // );
      return false;
    }

    var mydata = await connection();
    const web3 = new Web3(
      mydata &&
      mydata.provider &&
      mydata.provider != null &&
      mydata.provider != undefined &&
      mydata.provider != ""
        ? mydata.provider
        : window.ethereum
    );
    var currAddr = await web3.eth.getAccounts();
    console.log(currAddr);
    //if (window.ethereum) {
    // var web3 = new Web3(window.ethereum);
    if (web3 && web3.eth) {
      if ("BNB" == config.currencySymbol) {
        if ("BNB" == config.owntokenSymbol) {
          //  var ESCContract = new web3.eth.Contract(ESC_ABI, config.esctokenAddr);
          // const BEP721Contract = new web3.eth.Contract(
          //   BEP721_ABI,
          //   item.contractAddress
          // );
          //var currAddr = window.web3.eth.defaultAccount;
          // const EscContract = new web3.eth.Contract(
          //   ESC_ABI,
          //   config.esctokenAddr
          // );
          // var tokenBal = await ESCContract.methods
          //   .balanceOf(currAddr[0].toLowerCase())
          //   .call();
          // var tokenBalance = tokenBal / config.decimalvalues;
          // if (tokenBalance >= parseFloat(YouWillPay)) {
          //   // window.$('.modal').modal('hide');
          //   window.$("#proceed_modal").modal("show");
          // } else {
          //   toast.error("Insufficient balance", toasterOption);
          // }
          //   } else
          //    if (PurchaseCurrency == config.currencySymbol) {
          //     if (PurchaseBalance >= parseFloat(YouWillPay)) {
          //       // window.$('.modal').modal('hide');
          //       window.$("#proceed_modal").modal("show");
          //     } else {
          //       toast.error("Insufficient balance", toasterOption);
          //     }
          //   }
          // } else {
          var price =
            item.tokenowners_current && currentOwner.price
              ? currentOwner.price
              : 0;
          if (price && price > 0) {
            var tokenContractAddress = item.contractAddress.toString();
            var tokenType = item.type.toString();
            var bal = parseInt(item.balance);
            // var web3 = new Web3(window.ethereum);
            Set_FormSubmitLoading(true);
            var price1 =
              item.tokenowners_current && currentOwner.price
                ? currentOwner.price
                : 0;
            var owneradd = currentOwner.tokenOwner;
            var price = price;
            var sendAmount1 = (price * config.decimalvalues).toString();
            var lastAmt = (sendAmount1 * NoOfToken).toString();
            //lastAmt = parseFloat(lastAmt)*10000
            lastAmt = await convert(lastAmt);
            lastAmt = await ChecktokenDecimal(lastAmt, config.decimalvalues);
            //lastAmt = parseFloat(lastAmt)/10000 ;
            // window.$('#buy_modal').modal('show');
            var tokenaddress = "";
            if ("BNB" == config.currencySymbol) {
              tokenaddress = config.currencySymbol;
            } else if ("BNB" == config.tokenSymbol) {
              tokenaddress = config.owntokenSymbol;
            } else {
              tokenaddress = config.owntokenSymbol;
            }

            console.log(item, "itemitem");
            const BEP1155Contract = new web3.eth.Contract(
              BEP1155_ABI,
              item.contractAddress
            );

            const getBalans = await BEP1155Contract.methods
              .balanceOf(currentOwner.tokenOwner, item.tokenCounts)
              .call();

            console.log(currentOwner, "kkk");
            // "0x9c4c564906e31a4F42aade611b035B5B1C869606"
            // console.log(
            //   typeof getBalans,
            //   getBalans,
            //   item.tokenowners_current.balance,
            //   "result",
            //   typeof NoOfToken,
            //   NoOfToken,
            //   parseFloat(getBalans) < parseFloat(NoOfToken)
            // );

            console.log(getBalans, NoOfToken, "kkkkk");

            if (parseFloat(getBalans) < parseFloat(NoOfToken)) {
              toast.error(
                `Currently you don't have ${NoOfToken} nfts`,
                toasterOption
              );
              setTimeout(() => {
                //  window.location.reload();
              }, 1000);

              return false;
            }
            var GetServiceCont = new web3.eth.Contract(
              BEP1155_ABI,
              config.multipleContract
            );

            var fee = await GetServiceCont.methods.getServiceFee().call();
            let feeWithVal2 = (lastAmt * fee) / 1e20;
            let feeWithVal1 = parseFloat(lastAmt) + parseFloat(feeWithVal2);
            feeWithVal1 = convert(feeWithVal1);

            console.log(
              currentOwner.tokenOwner,
              item.tokenCounts,
              lastAmt.toString(),
              NoOfToken,
              "jjjjjj"
            );

            BEP1155Contract.methods
              .saleToken(
                currentOwner.tokenOwner,
                item.tokenCounts,
                lastAmt.toString(),
                NoOfToken
                // tokenaddress
              )
              .send({
                from: currAddr[0],
                value: feeWithVal1.toString(), // MultipleWei.toString()
              })
              .then(async (result) => {
                Set_FormSubmitLoading(false);
                var postData = {
                  tokenOwner: currentOwner.tokenOwner, // old owner
                  UserAccountAddr: currAddr[0], // new owner
                  tokenCounts: item.tokenCounts,
                  tokenType: item.type,
                  NoOfToken: item.type == 721 ? 1 : NoOfToken,
                  transactionHash: result.transactionHash,
                  PurchaseCurrency: config.currencySymbol,
                  ipfsimage: item.ipfsimage,
                  image: item.image,
                };
                var Resp = await PurchaseNow_Complete_Action(postData);
                console.log(Resp, "Resp");
                if (
                  Resp.data &&
                  Resp.data.toast &&
                  Resp.data.toast.type == "success"
                ) {
                  toast.success(
                    "Collectible purchase successfully",
                    toasterOption
                  );
                  Set_FormSubmitLoading(false);

                  // window.$(".modal").modal("hide");
                  // setTimeout(() => {
                  window.location.reload(false);
                  // }, 2000);
                }
              })
              .catch((error) => {
                console.log(error, "error");
                Set_FormSubmitLoading(false);
                toast.error("Transaction rejected by user", toasterOption);
              });
          } else {
            Set_FormSubmitLoading(false);
            toast.error("Oops something went wrong.!", toasterOption);
          }
        }
      }
    }
    //}
  }

  console.log(item, "FormSubmitLoading");

  return (
    <>
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="market_nft">
                <a href="/accessories" className="back_butn">
                  Back
                </a>
                <div className="market_nft_detail">
                  <div className="left_side">
                    <a href="#" className="nft_box">
                      <a href="/nft-detail" className="img_box">
                        <div className="bg"></div>
                        {item.Nft_type === 3 || item.Nft_type === 1 ? (
                          <img
                            // src={item?.ipfsimage}
                            src={
                              item.image
                                ? `${config.imgUrl}Nft/${item.image}`
                                : item.ipfsimage
                            }
                            alt="img"
                            className="img-fluid"
                          />
                        ) : (
                          <img
                            src={item?.ipfsimage}
                            // src={item.image?`${config.imgUrl}Nft/${item.image}`:item.ipfsimage}
                            alt="img"
                            className="img-fluid"
                          />
                        )}
                      </a>
                    </a>
                    <div className="custom_accordion">
                      <Accordion defaultActiveKey="0">
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>ATTRIBUTES</Accordion.Header>
                          <Accordion.Body>
                            <div className="nodata">No attributes</div>
                            <div className="note">
                              Attributes can enhance your gameplay in different
                              experiences.<a href="#">Learn more</a>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="1">
                          <Accordion.Header>ABOUT</Accordion.Header>
                          <Accordion.Body>
                            <div className="title">DESCRIPTION</div>
                            <div className="para">
                              Discover the new GN Quantum Levitator, a 3x3
                              blocks cyberpunk flying platform infused with
                              alien tech. Stable and stylish, it propels players
                              through neon-soaked landscapes, merging futuristic
                              design with reliability.
                            </div>
                            <div className="title">CATEGORY</div>
                            <div className="para">Gameplay {">"} Platforms</div>
                            <div className="title">TOKEN ID</div>
                            <div className="para mb-0 break-word">
                              24522549224064955614122410608851940716392351867020949179
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="2">
                          <Accordion.Header>TECHNICAL SPECS</Accordion.Header>
                          <Accordion.Body>
                            <div className="table_box">
                              <div className="justify-content-end grey">
                                <div>X</div>
                                <div>Y</div>
                                <div>Z</div>
                              </div>
                              <div>
                                <div className="d-flex align-items-center gap-1">
                                  <img
                                    src={require("../assets/images/voxels.png")}
                                    alt="img"
                                    className="icons img-fluid"
                                  />
                                  <span>Voxels</span>
                                </div>
                                <div>X</div>
                                <div>Y</div>
                                <div>Z</div>
                              </div>
                              <div>
                                <div className="d-flex align-items-center gap-1">
                                  <img
                                    src={require("../assets/images/meters.png")}
                                    alt="img"
                                    className="icons img-fluid"
                                  />
                                  <span>Meters</span>
                                </div>
                                <div>X</div>
                                <div>Y</div>
                                <div>Z</div>
                              </div>
                            </div>
                            <div className="note">
                              Size may impact how an NFT looks in an experience.
                              <a href="#">Learn more</a>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="3">
                          <Accordion.Header>LISTINGS</Accordion.Header>
                          <Accordion.Body>
                            <div className="table_box">
                              <div className="justify-content-end grey">
                                <div>X</div>
                                <div>Y</div>
                                <div>Z</div>
                              </div>
                              <div>
                                <div className="d-flex align-items-center gap-1">
                                  <img
                                    src={require("../assets/images/voxels.png")}
                                    alt="img"
                                    className="icons img-fluid"
                                  />
                                  <span>Voxels</span>
                                </div>
                                <div>X</div>
                                <div>Y</div>
                                <div>Z</div>
                              </div>
                              <div>
                                <div className="d-flex align-items-center gap-1">
                                  <img
                                    src={require("../assets/images/meters.png")}
                                    alt="img"
                                    className="icons img-fluid"
                                  />
                                  <span>Meters</span>
                                </div>
                                <div>X</div>
                                <div>Y</div>
                                <div>Z</div>
                              </div>
                            </div>
                            <div className="note">
                              Size may impact how an NFT looks in an experience.
                              <a href="#">Learn more</a>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item>

                        {/* <Accordion.Item eventKey="4">
                          <Accordion.Header>LISTINGS</Accordion.Header>
                          <Accordion.Body className="p-0">
                            <div className="custom_table">
                              <div class="table-responsive">
                                <table class="table mb-0">
                                  <thead>
                                    <tr>
                                      <th scope="col">PRICE</th>
                                      <th scope="col">AVAILABLE</th>
                                      <th scope="col">LISTED BY</th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {}
                                    <tr>
                                      <td scope="row">
                                        <div className="wid">
                                          <div className="d-flex align-items-center gap-1">
                                            <img
                                              src={require("../assets/images/sand.png")}
                                              alt="img"
                                              className="icons img-fluid"
                                            />
                                            <p>29.00 SAND</p>
                                          </div>
                                          <span>20.63 USD</span>
                                        </div>
                                      </td>
                                      <td>5</td>
                                      <td>
                                        {" "}
                                        <span className="blue"> @temio</span>
                                      </td>
                                      <td>
                                        <div className="d-flex align-items-center gap-3">
                                          <div className="buy_btn">Buy</div>

                                          <div className="cart">
                                            <img
                                              src={require("../assets/images/cart.png")}
                                              alt="img"
                                              className="icons img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Accordion.Body>
                        </Accordion.Item> */}
                      </Accordion>
                    </div>
                  </div>
                  <div>
                    <div className="right_side">
                      <div className="icons_flx">
                        <div className="circle cursor-pointer">
                          <img
                            src={require("../assets/images/like.png")}
                            alt="img"
                            className="img-fluid"
                          />
                        </div>
                        <div>
                          <div className="drp_dwn opacity-100">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item
                                  href="#/action-2"
                                  onClick={handleShow}
                                >
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      <h5>{item?.tokenName}</h5>
                      <div className="fl_x">
                        <div>
                          <img
                            src={require("../assets/images/minute.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <span>{item?.balance} minted</span>
                        </div>
                        <div>
                          <img
                            src={require("../assets/images/prop.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <span>Prop</span>
                        </div>
                      </div>
                      <div className="prf">
                        <img
                          src={require("../assets/images/icon_03.png")}
                          alt="img"
                          className="img-fluid"
                        />
                        <div>
                          <p className="sm_txt">CREATED BY</p>
                          <p className="blue">{item?.tokenCreator}</p>
                        </div>
                      </div>
                      <div className="buy_box">
                        <div className="h_tit">
                          <img
                            src={require("../assets/images/polygon.png")}
                            alt="img"
                            className="img-fluid polygon"
                          />
                          POLYGON | Available from this seller: 5
                        </div>
                        <div className="value">
                          <img
                            src={require("../assets/images/sand.png")}
                            alt="img"
                            className="img-fluid"
                          />
                          <h6>
                            {item?.price} SAND{" "}
                            <span>{item.price * 10} USD</span>
                          </h6>
                        </div>
                        {item.tokenowners_current &&
                          item.tokenowners_current.length > 0 &&
                          item.tokenowners_current.map((itemCur, i) => {
                            return (
                              <>
                                {itemCur.price > 0 &&
                                  itemCur.balance > 0 &&
                                  itemCur.tokenOwner !=
                                    walletConnection.address && (
                                    <div className="buy_line">
                                      {/* <button onClick={handleShow1}>Buy</button>
                                      <div className="circle cursor-pointer p-0">
                                        <img
                                          src={require("../assets/images/cart.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </div> */}
                                    </div>
                                  )}
                                {itemCur.price <= 0 &&
                                  itemCur.balance > 0 &&
                                  itemCur.tokenOwner ==
                                    walletConnection.address && (
                                    <div className="buy_line">
                                      <button onClick={handleShow2}>
                                        Put On Sale
                                      </button>
                                      <div className="circle cursor-pointer p-0">
                                        <img
                                          src={require("../assets/images/cart.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  )}
                                {itemCur.price > 0 &&
                                  itemCur.balance > 0 &&
                                  itemCur.tokenOwner ==
                                    walletConnection.address && (
                                    <div className="buy_line">
                                      <button onClick={handleShow2}>
                                        Change Price
                                      </button>
                                      <div className="circle cursor-pointer p-0">
                                        <img
                                          src={require("../assets/images/cart.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  )}
                                {itemCur.price > 0 &&
                                  itemCur.balance > 0 &&
                                  itemCur.tokenOwner ==
                                    walletConnection.address && (
                                    <div className="buy_line">
                                      <button onClick={handleShow3}>
                                        Cancel Order
                                      </button>
                                      <div className="circle cursor-pointer p-0">
                                        <img
                                          src={require("../assets/images/cart.png")}
                                          alt="img"
                                          className="img-fluid"
                                        />
                                      </div>
                                    </div>
                                  )}
                              </>
                            );
                          })}

                        <div className="ylw_t">
                          💫 You’re buying from the creator 💫
                        </div>
                      </div>
                      <div className="custom_tab mt-4">
                        <Tab.Container
                          id="left-tabs-example"
                          defaultActiveKey="first"
                        >
                          <Nav>
                            <Nav.Item>
                              <Nav.Link eventKey="first">Owner</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                              <Nav.Link eventKey="second">History</Nav.Link>
                            </Nav.Item>
                          </Nav>
                          <Tab.Content>
                            <Tab.Pane eventKey="first">
                              <div className="own_box mt-3">
                                <ul class="p-0 list-unstyled">
                                  <li>
                                    <div class="mb-3">
                                      <div class="d-flex mb-2">
                                        <div class="p_list align-items-start">
                                          <div class="p_list_pp">
                                            <img
                                              src={require("../assets/images/icon_03.png")}
                                              alt="img"
                                            />
                                          </div>
                                          <div class="p_list_info">
                                            {item.tokenowners_current &&
                                              item.tokenowners_current.length >
                                                0 &&
                                              item.tokenowners_current.map(
                                                (itemCur, i) => {
                                                  let checkaddress =
                                                    item.tokenowners_current?.findIndex(
                                                      (el) =>
                                                        el?.tokenOwner?.toLowerCase() ==
                                                        walletConnection?.address?.toLowerCase()
                                                    );
                                                  console.log(
                                                    checkaddress,
                                                    "checkaddress"
                                                  );

                                                  return (
                                                    <>
                                                      <span>
                                                        <b>
                                                          <span>
                                                            {itemCur.tokenOwner}
                                                          </span>
                                                        </b>
                                                        <div class="p-0">
                                                          {itemCur.price > 0 ? (
                                                            <p>
                                                              {itemCur.balance}/
                                                              {itemCur.quantity}{" "}
                                                              on sale for{" "}
                                                              {convert(
                                                                itemCur.price
                                                              )}{" "}
                                                              {config.symbol}{" "}
                                                              {itemCur.quantity >
                                                                0 && "each"}
                                                            </p>
                                                          ) : itemCur.price <=
                                                            0 ? (
                                                            <p>
                                                              {itemCur.balance}/
                                                              {itemCur.quantity}{" "}
                                                              Not for sale
                                                            </p>
                                                          ) : (
                                                            <></>
                                                          )}
                                                        </div>
                                                        {item.Nft_type === 2 ? (
                                                          <>
                                                            {itemCur.price >
                                                              0 &&
                                                              itemCur.balance >
                                                                0 &&
                                                              itemCur.tokenOwner !=
                                                                walletConnection.address &&
                                                              checkaddress ==
                                                                -1 && (
                                                                <div class="mt-3 d-flex gap-2 flex-wrap">
                                                                  <div className="avatar_action_btns">
                                                                    <Button
                                                                      class="butn"
                                                                      onClick={() =>
                                                                        handleShow1(
                                                                          item,
                                                                          itemCur
                                                                        )
                                                                      }
                                                                      variant="contained"
                                                                    >
                                                                      Buy
                                                                    </Button>
                                                                  </div>
                                                                </div>
                                                              )}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {itemCur.price >
                                                              0 &&
                                                              itemCur.balance >
                                                                0 &&
                                                              itemCur.tokenOwner !=
                                                                walletConnection.address && (
                                                                <div class="mt-3 d-flex gap-2 flex-wrap">
                                                                  <div className="avatar_action_btns">
                                                                    <Button
                                                                      class="butn"
                                                                      onClick={() =>
                                                                        handleShow1(
                                                                          item,
                                                                          itemCur
                                                                        )
                                                                      }
                                                                      variant="contained"
                                                                    >
                                                                      Buy
                                                                    </Button>
                                                                  </div>
                                                                </div>
                                                              )}
                                                          </>
                                                        )}

                                                        <hr></hr>
                                                      </span>
                                                    </>
                                                  );
                                                }
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="second">
                              <div className="own_box mt-3">
                                <ul class="p-0 list-unstyled">
                                  {activity &&
                                    activity.map((list, index) => (
                                      <li>
                                        {console.log(list, "list")}
                                        <div class="mb-3">
                                          <div class="d-flex mb-2">
                                            <div class="p_list">
                                              <div class="p_list_pp">
                                                <img
                                                  src={
                                                    item.Nft_type === 3 ||
                                                    item.Nft_type === 1
                                                      ? `${config.imgUrl}Nft/${item.image}`
                                                      : item.ipfsimage
                                                  }
                                                  alt="img"
                                                />
                                              </div>

                                              <div class="p_list_info">
                                                <span>
                                                  <b>
                                                    <span>
                                                      {`${list.actiontype} by ${
                                                        list.actiontype ==
                                                        "Purchase"
                                                          ? list.toAddress
                                                          : list.fromAddress
                                                      } on ${moment(
                                                        list.createdAt
                                                      ).format(
                                                        "MMMM Do YYYY, h:mm:ss a"
                                                      )}`}
                                                      {/* Created by 0xa1a....2a4fb on
                                                      December 18th 2023, 4:05 pm */}
                                                    </span>
                                                  </b>
                                                </span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </Tab.Pane>
                          </Tab.Content>
                        </Tab.Container>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h_flx">
                  <p>
                    MORE FROM{" "}
                    <span className="blue cursor-pointer"> @temio</span>
                  </p>
                  <a href="#" className="text-light">
                    Discover more
                    <div className="circle">
                      <img
                        src={require("../assets/images/left.png")}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                  </a>
                </div>
                <Row>
                  {additionaldata &&
                    additionaldata.map((list, index) => {
                      if (list.tokenCounts != item.tokenCounts)
                        return (
                          <Col lg="4" xl="3" className="d-flex">
                            {console.log(list, "lista")}
                            <a href="#" className="nft_box w-100">
                              <a
                                href={`/nft-detail/${list.tokenCounts}`}
                                className="img_box"
                              >
                                <div className="bg"></div>
                                <img
                                  src={
                                    item.Nft_type === 3 || item.Nft_type === 1
                                      ? `${config.imgUrl}Nft/${list.image}`
                                      : list.ipfsimage
                                  }
                                  alt="img"
                                  className="img-fluid"
                                />
                              </a>
                              <a href="/nft-detail" className="mb-2">
                                {list.tokenName}
                              </a>
                              <p className="mb-3">
                                <img
                                  src={require("../assets/images/minute.png")}
                                  alt="img"
                                  className="img-fluid me-2"
                                />
                                {list.tokenQuantity}
                                {/* 3/3 */}
                              </p>
                              <div className="flx">
                                <div>
                                  <h6>{list.price} SAND</h6>
                                  {/* <p>66.55 USD</p> */}
                                </div>
                              </div>
                            </a>
                          </Col>
                        );
                    })}

                  {/* <Col lg="4" xl="3" className="d-flex">
                    <a href="#" className="nft_box w-100">
                      <a href="/nft-detail" className="img_box">
                        <div className="bg"></div>
                        <img
                          src={require("../assets/images/nft/m1.avif")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                      <a href="/nft-detail" className="mb-2">
                        poli s Yedinorogom
                      </a>
                      <p className="mb-3">
                        <img
                          src={require("../assets/images/minute.png")}
                          alt="img"
                          className="img-fluid me-2"
                        />
                        3/3
                      </p>
                      <div className="flx">
                        <div>
                          <h6>450.00 SAND</h6>
                          <p>313.74 USD</p>
                        </div>
                      </div>
                    </a>
                  </Col>
                  <Col lg="4" xl="3" className="d-flex">
                    <a href="#" className="nft_box w-100">
                      <a href="/nft-detail" className="img_box">
                        <div className="bg"></div>
                        <img
                          src={require("../assets/images/nft/m2.avif")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                      <a href="/nft-detail" className="mb-2">
                        A Prisoner
                      </a>
                      <p className="mb-3">
                        <img
                          src={require("../assets/images/minute.png")}
                          alt="img"
                          className="img-fluid me-2"
                        />
                        3/3
                      </p>
                      <div className="flx">
                        <div>
                          <h6>95.00 SAND</h6>
                          <p>66.55 USD</p>
                        </div>
                      </div>
                    </a>
                  </Col>
                  <Col lg="4" xl="3" className="d-flex">
                    <a href="#" className="nft_box w-100">
                      <a href="/nft-detail" className="img_box">
                        <div className="bg"></div>
                        <img
                          src={require("../assets/images/nft/m3.avif")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                      <a href="/nft-detail" className="mb-2">
                        Hot Girl - Flower Girl
                      </a>
                      <p className="mb-3">
                        <img
                          src={require("../assets/images/minute.png")}
                          alt="img"
                          className="img-fluid me-2"
                        />
                        3/3
                      </p>
                      <div className="flx">
                        <div>
                          <h6>42.00 SAND</h6>
                          <p>29.42 USD</p>
                        </div>
                      </div>
                    </a>
                  </Col>
                  <Col lg="4" xl="3" className="d-flex">
                    <a href="#" className="nft_box w-100">
                      <a href="/nft-detail" className="img_box">
                        <div className="bg"></div>
                        <img
                          src={require("../assets/images/nft/m4.avif")}
                          alt="img"
                          className="img-fluid"
                        />
                      </a>
                      <a href="/nft-detail" className="mb-2">
                        Vistrea of Lubricanto - Tower of Myths
                      </a>
                      <p className="mb-3">
                        <img
                          src={require("../assets/images/minute.png")}
                          alt="img"
                          className="img-fluid me-2"
                        />
                        3/3
                      </p>
                      <div className="flx">
                        <div>
                          <h6>5.00 SAND</h6>
                          <p>3.50 USD</p>
                        </div>
                      </div>
                    </a>
                  </Col> */}
                </Row>
              </div>
            </div>
          </div>
        </div>

        {/* Cancel Sale Order*/}
        <Modal
          className="custom_modal"
          centered
          show={show3}
          onHide={handleClose3}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Cancel Sale Order</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {/* <Col lg={6}> */}
              {/* <p className="mb-0 grey">Token Price</p> */}
              {/* </Col> */}
              <Col lg={8}>
                {/* <div className="mb-3"> 0.001</div> */}
                Are You Sure to Cancle this Sale?
              </Col>
            </Row>
            <br></br>
            <div className="d-flex gap-2 flex-wrap justify-content-between">
              <button class="butn" onClick={CancelOrder_Click}>
                Continue
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* put on sale  */}
        <Modal
          className="custom_modal"
          centered
          show={show2}
          onHide={handleClose2}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>NFT for Sale</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <a href="#" className="nft_box">
              <a href="/nft-detail" className="img_box">
                <div className="bg"></div>
                <img
                  src={
                    item.Nft_type == 2
                      ? item?.ipfsimage
                      : `${config.imgUrl}/Nft/${item.image}`
                  }
                  alt="img"
                  className="img-fluid"
                />
              </a>
            </a>
            <p className="grey">
              You are about to place order for{" "}
              <span className="text-light">GN Quantum Levitator platform</span>
              from
            </p>
            <div class="mb-3 input_box">
              <input
                type="number"
                class="form-control"
                aria-describedby="helpId"
                placeholder="New price"
                onChange={(e) => InputChangeprice(e)}
              />
            </div>

            <Row>
              <Col lg={6}>
                <p className="mb-0 grey">BNB Service fee</p>
              </Col>
              <Col lg={6}>
                <div className="mb-3"> {servicefee / 1e18} %</div>
              </Col>
              <Col lg={6}>
                <p className="mb-0 grey">You will get</p>
              </Col>
              <Col lg={6}>
                <div className="mb-3"> {YouWillPay} BNB</div>
              </Col>
            </Row>
            <div className="d-flex gap-2 flex-wrap justify-content-between">
              <button class="cancel_btn">Cancel</button>
              <button
                class="butn"
                onClick={() => PutOnSale_Click(item, BuyOwnerDetailFirst, "PC")}
              >
                Continue
              </button>
            </div>
          </Modal.Body>
        </Modal>

        {/* Checkout  */}
        <Modal
          className="custom_modal"
          centered
          show={show1}
          onHide={handleClose1}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Checkout</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              <Col lg={6}>
                <p className="mb-0 grey">Seller</p>
                <div>
                  {" "}
                  <strong>{halfAddrShow(seller)}</strong>
                </div>
              </Col>
              <Col lg={6}>
                <p className="mb-0 grey">Buyer</p>
                <div>
                  {" "}
                  <strong> {halfAddrShow(walletConnection.address)}</strong>
                </div>
              </Col>
            </Row>
            <div className="text-center bdr py-3 my-3 pt-4">
              <div>
                {" "}
                <h4>Your balance : {PurchaseBalance.toFixed(2)} BNB</h4>
              </div>
              <p className="grey">Available : {available}</p>
            </div>
            {item.Nft_type != 2 ? (
              <input
                type="number"
                onKeyDown={(evt) =>
                  ["e", "E", "+", "-", ",", "."].includes(evt.key) &&
                  evt.preventDefault()
                }
                min="0"
                name="NoOfToken"
                id="NoOfToken"
                class="form-control mb-0 w-100"
                placeholder="Enter item quantity"
                aria-label="bid"
                aria-describedby="basic-addon2"
                onChange={InputChange}
                // value={NoOfToken}
                value={VQuattity}
                autoComplete="off"
                // disabled={}
              />
            ) : (
              <>
                <Row>
                  <Col lg={6}>
                    <p className="mb-0 grey">No Of Token</p>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3"> 1 </div>
                  </Col>
                </Row>
              </>
            )}
            <Row>
              <Col lg={6}>
                <p className="mb-0 grey">Price</p>
              </Col>
              <Col lg={6}>
                <div className="mb-3"> {price} BNB</div>
              </Col>
              <Col lg={6}>
                <p className="mb-0 grey">BNB Service fee</p>
              </Col>
              <Col lg={6}>
                <div className="mb-3"> {servicefee / 1e18} %</div>
              </Col>
              <Col lg={6}>
                <p className="mb-0 grey">You will pay</p>
              </Col>
              <Col lg={6}>
                <div className="mb-3"> {YouWillPay} BNB</div>
              </Col>
            </Row>
            <div className="d-flex gap-2 flex-wrap justify-content-between">
              <button class="cancel_btn">Cancel</button>
              <button class="butn" onClick={FormSubmit}>
                Proceed to payment
              </button>
            </div>
          </Modal.Body>
        </Modal>
        {/* Report nft */}
        <Modal
          className="custom_modal"
          show={show}
          onHide={handleClose}
          animation={false}
        >
          <Modal.Header closeButton>
            <Modal.Title>Report NFT</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Pornographic content`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Hateful or abusive content`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Harassment or bullying`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Threats of harm or encouragement of self harm`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Promotion or glorification of real life violence`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Infringes on the rights of another`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Infringes on my rights`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Spam`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="custom_chk">
              <Form.Check
                type={"radio"}
                id={`default-radio`}
                label={`Scams`}
                name="grp"
              />
              <div>
                <img
                  src={require("../assets/images/info.png")}
                  alt="img"
                  className="img-fluid"
                />
              </div>
            </div>
            <p className="text mt-5 pt-4">
              Accounts are penalized for Community Guidelines violations, and
              serious or repeated violations can lead to account termination.
            </p>
            <p className="text">
              Join the discussion about{" "}
              <a href="#" className="blue">
                {" "}
                Community Guidelines
              </a>{" "}
              violation on Discord.
            </p>
            <div className="d-flex align-items-center justify-content-between flex-wrap">
              <button className="cancel_btn">Cancel</button>
              <button className="butn">Continue</button>
            </div>
          </Modal.Body>
        </Modal>
      </div>

      {FormSubmitLoading && (
        <div className="loadBefore">
          <PropagateLoader
            color={"#72d438"}
            loading={FormSubmitLoading}
            cssOverride={override}
            size={25}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      )}
    </>
  );
}
