import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import { Await, Link, Navigate, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { Scrollbars } from "react-custom-scrollbars";
import NFT1155 from "../ABI/NFT1155.json";
import { useSelector, useDispatch } from "react-redux";
//STATE ACTIONS
import {
  setSettings,
  setAvatar,
  setCustomMen,
} from "../redux/action/toolpage/action";
import config from "../config/index.js";
import { bignumber, multiply } from "mathjs";

//COMPONENTS
import LoadEnv from "../scene/LoadEnv";
import AnimationTypes from "../components/tool/AnimationTypes";
import {
  TokenAddItemAction,
  TokenAddOwnerAction,
  Token_Get_Detials_Action,
  PurchaseNow_Complete_Action,
  TokenPriceChange_update_Action,
  getCategoryData,
  getNftByCat,
  getUserAvatar,
  get_User_Accessories,
} from "../apiAction/nfts.js";
import { toast } from "react-toastify";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Avatar(props) {
  //STATE
  const avatarTool = useSelector((state) => state.avatarTool);
  const walletConnection = useSelector((state) => state.walletConnection);
  const dispatch = useDispatch();

  const [activeButton, setActiveButton] = useState(1);
  const [BuyOwnerDetailFirst, Set_BuyOwnerDetailFirst] = useState({});
  const [NoOfToken, Set_NoOfToken] = useState(1);
  const [item, Set_item] = useState({});
  const [TokenPrice, Set_TokenPrice] = useState(0.001);
  const [catList, setcatList] = useState({});
  const [NftData, setNftData] = useState([]);
  const [animationTypes, setAnimationTypes] = useState([]);

  const [Myavatar, setMyavatar] = useState([]);

  const [MyselAvatar, setMyselAvatar] = useState();

  const handleButtonClick = (buttonId) => {
    if (buttonId == 1) {
      //CUSTOM AVATAR
      let Details = {
        animationType: "idle",
        model: avatarTool?.settings?.model,
        customize: "custom-avatar",
      };
      dispatch(setSettings(Details));
    } else if (buttonId == 2) {
      //ANIMATION SET TO IDLE & MY-AVATAR
      let Details = {
        animationType: "idle",
        model: avatarTool?.settings?.model,
        customize: "my-avatar",
      };
      dispatch(setSettings(Details));
    } else if (buttonId == 3) {
      //ANIMATIONS
      getAllAnimationTypes();
    }
    setActiveButton(buttonId);
  };

  const navigate = useNavigate();
  const handleClickCustomize = () => {
    //STATE CHANGES
    let Details = {
      info: avatarTool?.settings,
    };
    Details.info.customize = "custom-avatar";
    dispatch(setSettings(Details?.info));
    navigate("/customize-avatar");
  };

  const [showDiv, setShowDiv] = useState(false);

  const toggleSidebarFilter = () => {
    setShowDiv(!showDiv);
  };

  const [showAssetsOverviewMobDiv, setShowAssetsOverviewMobDiv] =
    useState(false);

  const toggleAssetsOverviewMobFilter = () => {
    setShowAssetsOverviewMobDiv(!showAssetsOverviewMobDiv);
  };

  const navigation = async (list) => {
    if (list?.my_nft?.Nft_type !== 4) {
      navigate(`/nft-detail/${list.my_nft.tokenCounts}`);
    } else {
      //DEFAULT ASSETS RENDER
      let style = list?.my_nft?.image?.split(".")[0];
      let Details = {
        info: avatarTool?.men,
      };
      Details.info.option = list.my_nft.category_name.toLowerCase();
      Details.info.hair.type = style;
      dispatch(setCustomMen(Details));
    }
  };

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 5,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 4,
          infinite: true,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 3,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  useEffect(() => {
    HandleGetcatData();
    if (walletConnection.address) {
      getMyAvatarList();

      getTokenOwnerInfo();
    }
  }, [walletConnection.address]);

  const getTokenOwnerInfo = async () => {
    try {
      if (walletConnection.address) {
        let tokenId = 1711956951;
        const payload = {
          curAddr: walletConnection.address,
          tokenCounts: tokenId,
        };
        let Resp = await Token_Get_Detials_Action(payload);
        console.log(Resp, "RespRespResp");
        if (
          Resp &&
          Resp &&
          Resp.data &&
          Resp.data.Detail &&
          Resp.data.Detail.Resp
        ) {
          let TokenResp = Resp.data.Detail.Resp;
          if (
            TokenResp &&
            TokenResp.Token &&
            TokenResp.Token[0] &&
            TokenResp.Token[0].tokenowners_current
          ) {
            for (
              let i = 0;
              i < TokenResp.Token[0].tokenowners_current.length;
              i++
            ) {
              const element = TokenResp.Token[0].tokenowners_current[i];
              if (
                element.balance > 0 &&
                //element.price > 0 &&
                element.tokenOwner != walletConnection.address
              ) {
                console.log(element, "elementelementelement");
                Set_BuyOwnerDetailFirst(element);
                break;
              }
            }

            if (TokenResp.Token && TokenResp.Token[0]) {
              Set_item(TokenResp.Token[0]);
            }
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  //GET ALL - ANIMATIONS TYPES
  const getAllAnimationTypes = async () => {
    try {
      let reqbody = {
        type: "animation",
        address: walletConnection.address,
      };
      let data = await get_User_Accessories(reqbody);
      setAnimationTypes(data?.data);
    } catch (err) {
      console.log(err);
    }
  };

  //SET ANIMATIONS
  const setAnimations = (animation) => {
    let Details = {
      animationType: animation,
      model: avatarTool?.settings?.model,
      customize: avatarTool?.settings?.customize,
    };
    dispatch(setSettings(Details));
  };

  //SET AVATAR
  const setMyAvatar = (glbPath, id) => {
    //STATE CHANGES
    setMyselAvatar(id);

    console.log(glbPath, "glbPath");

    let Details = {
      info: avatarTool?.myAvatar,
    };
    Details.info.glb = glbPath;
    dispatch(setAvatar(Details));
  };

  function convert(n) {
    var sign = +n < 0 ? "-" : "",
      toStr = n.toString();
    if (!/e/i.test(toStr)) {
      return n;
    }
    var [lead, decimal, pow] = n
      .toString()
      .replace(/^-/, "")
      .replace(/^([0-9]+)(e.*)/, "$1.$2")
      .split(/e|\./);
    return +pow < 0
      ? sign +
          "0." +
          "0".repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) +
          lead +
          decimal
      : sign +
          lead +
          (+pow >= decimal.length
            ? decimal + "0".repeat(Math.max(+pow - decimal.length || 0, 0))
            : decimal.slice(0, +pow) + "." + decimal.slice(+pow));
  }

  async function ChecktokenDecimal(amount, decimals) {
    var result = 0;
    var decimalsLength = 18;
    if (decimals && decimals > 0) {
      decimalsLength = decimals;
    }
    try {
      var number = amount.toString();
      var number1 = number.split(".");
      if (number1[1] && number1[1].length && number1[1].length > 0) {
        result = number1[0];
      } else {
        result = amount;
      }
      return result;
    } catch (err) {
      return result;
    }
  }

  const handleNFTBuy = async (item, BuyOwnerDe) => {
    try {
      console.log(item, "item");
      if (walletConnection.address) {
        item.tokenowners_current = {};
        item.tokenowners_current = BuyOwnerDe;
        var price =
          item.tokenowners_current && item.tokenowners_current.price
            ? item.tokenowners_current.price
            : 0;
        if (price && price > 0) {
          var price = price;
          var sendAmount1 = price * 1e18;
          var lastAmt = sendAmount1 * NoOfToken;
          lastAmt = await convert(lastAmt);
          lastAmt = await ChecktokenDecimal(lastAmt, config.decimalvalues);
          let web3 = walletConnection.web3;

          var multiNFTContract = new web3.eth.Contract(
            NFT1155,
            config.multipleContract
          );
          const getBalans = await multiNFTContract.methods
            .balanceOf(item.tokenowners_current.tokenOwner, item.tokenCounts)
            .call();
          if (parseFloat(getBalans) < parseFloat(NoOfToken)) {
            alert(`Currently you don't have ${NoOfToken} nfts`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
            return false;
          }
          var fee = await multiNFTContract.methods.getServiceFee().call();
          let feeWithVal2 = (lastAmt * fee) / 1e20;
          let feeWithVal1 = parseFloat(lastAmt) + parseFloat(feeWithVal2);
          feeWithVal1 = convert(feeWithVal1);
          multiNFTContract.methods
            .saleToken(
              item.tokenowners_current.tokenOwner,
              item.tokenCounts,
              lastAmt.toString(),
              NoOfToken
            )
            .send({
              from: walletConnection.address,
              value: feeWithVal1.toString(), //MultipleWei.toString()
            })
            .then(async (result) => {
              var postData = {
                tokenOwner: item.tokenowners_current.tokenOwner, // old owner
                UserAccountAddr: walletConnection.address, // new owner
                tokenCounts: item.tokenCounts,
                tokenType: item.type,
                NoOfToken: item.type == 721 ? 1 : NoOfToken,
                tokenName: item.tokenName,
                transactionHash: result.transactionHash,
                PurchaseCurrency: config.currencySymbol,
                ipfsimage: item.ipfsimage,
                image: item.image,
                contract: item.contractAddress,
              };
              var Resp = await PurchaseNow_Complete_Action(postData);
              if (
                Resp.data &&
                Resp.data.toast &&
                Resp.data.toast.type == "success"
              ) {
                setTimeout(() => {
                  window.location.reload(false);
                }, 2000);
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      } else {
        alert("Wallet not connected");
      }
    } catch (err) {
      console.log(err);
    }
  };
  const PutOnSale_Click = async (item, BuyOwnerDetailFirst, flog) => {
    try {
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        var multiNFTContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        var newtoken = TokenPrice;
        var price = bignumber(newtoken);
        price = multiply(price, 10 ** 18);
        price = await convert(price);
        const getBalans = await multiNFTContract.methods
          .balanceOf(walletConnection.address, item.tokenCounts)
          .call();
        console.log(
          getBalans,
          "result",
          walletConnection.address.toLowerCase()
        );
        if (parseInt(getBalans) == 0) {
          alert("Currently you nothing have any nfts");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }
        await multiNFTContract.methods
          .orderPlace(item.tokenCounts, price.toString())
          .send({ from: walletConnection.address })
          .then(async (result) => {
            var postData = {
              tokenOwner: walletConnection.address,
              tokenCounts: item.tokenCounts,
              price: TokenPrice,
              // biddingtoken: biddingtoken,
              blockHash: result.blockHash,
              transactionHash: result.transactionHash,
              // TokenPrice_Initial: TokenPrice_Initial,
            };
            var Resp = await TokenPriceChange_update_Action(postData);
            if (
              Resp.data &&
              Resp.data.RetType &&
              Resp.data.RetType == "success"
            ) {
              alert(" NFT places for sale on marketplace");
              setTimeout(() => {
                window.location.reload(false);
              }, 2000);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const CancelOrder_Click = async (item, BuyOwnerDetailFirst) => {
    try {
      if (walletConnection.address) {
        let web3 = walletConnection.web3;
        var multiNFTContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        const getBalans = await multiNFTContract.methods
          .balanceOf(walletConnection.address, item.tokenCounts)
          .call();
        if (parseInt(getBalans) == 0) {
          alert("Currently you nothing have any nfts");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
          return false;
        }
        multiNFTContract.methods
          .cancelOrder(item.tokenCounts)
          .send({ from: walletConnection.address })
          .then(async (result) => {
            var postData = {
              tokenOwner: walletConnection.address,
              tokenCounts: item.tokenCounts,
              tokenPrice: 0,
              // blockHash: result.transactionHash,
              transactionHash: result.transactionHash,
              BidToken: "ETH",
              from: "cancel",
            };
            var Resp = await TokenPriceChange_update_Action(postData);
            if (
              Resp.data &&
              Resp.data.RetType &&
              Resp.data.RetType == "success"
            ) {
              alert("Sale order cancelled successfully");
              setTimeout(() => {
                window.location.reload(false);
              }, 1500);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleMintSmaple = async () => {
    try {
      if (walletConnection.address) {
        // return
        let web3 = walletConnection.web3;
        var multiNFTContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        var unix = Math.round(new Date() / 1000);
        let ran = Math.floor(Math.random() * 100 + 1);
        let tokenid = unix + ran;
        let Price = 0.001 * 1e18;
        let TokenPriceInStr = Price.toString();
        let nftName = "NFT" + tokenid;
        let TokenQuantity = 20;
        let ipfs_url =
          "https://aqua-back-python-620.mypinata.cloud/ipfs/QmbxBfFyCKjaHKzLPGmxF3ZakFVN4KnBNZZXQSuBh1aaUH/1.png";
        let ipfs_hash = "QmbxBfFyCKjaHKzLPGmxF3ZakFVN4KnBNZZXQSuBh1aaUH/1.png";
        await multiNFTContract.methods
          .mint(
            tokenid,
            TokenPriceInStr,
            "5",
            TokenQuantity.toString(),
            nftName,
            ipfs_hash,
            ipfs_url
          )
          .send({ from: walletConnection.address })
          .then(async (result) => {
            var transactionHash = "";
            if (result.transactionHash) {
              transactionHash = result.transactionHash;
            } else {
              transactionHash = result;
            }
            var TokenAddItemPayload = {
              Image: "",
              ipfsimage: ipfs_url,
              Name: nftName,
              Count: tokenid,
              Description: "TokenDescription",
              Price: 0.001,
              TokenPrice: 0,
              Royalities: 5,
              Category_label: "Music",
              Owner: walletConnection.address,
              Creator: walletConnection.address,
              Quantity: TokenQuantity,
              Balance: TokenQuantity,
              ContractAddress: config.multipleContract,
              Status: true,
              HashValue: transactionHash,
              Type: 1155,
              MinimumBid: 0,
              MaximumBid: 0,
              Clocktime: "",
              EndClocktime: "",
              UnLockcontent: "",
              PutOnSale: true,
              PutOnSaleType: "FixedPrice",
              currencyName: "ETH",
            };
            await TokenAddItemAction(TokenAddItemPayload);
            var TokenAddOwnerPayload = {
              Count: tokenid,
              Price: 0.001,
              TokenPrice: 0,
              Owner: walletConnection.address,
              Balance: TokenQuantity,
              Quantity: TokenQuantity,
              ContractAddress: config.multipleContract,
              Type: 1155,
              HashValue: transactionHash,
              Status: true,
              currencyName: "ETH",
            };
            await TokenAddOwnerAction(TokenAddOwnerPayload);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const HandleGetcatData = async () => {
    try {
      let data = await getCategoryData();
      setcatList(data?.data);

      if (walletConnection.address) {
        await getNftbyCat(data?.data[0]?._id);
      }
    } catch (err) {}
  };

  const getNftbyCat = async (id) => {
    try {
      if (!walletConnection.address) {
        toast.error("Please connect your Wallet");
        return false;
      }

      let data = await getNftByCat({
        id: id,
        type: 2,
        address: walletConnection.address,
      });
      console.log(data, "data----1");
      setNftData(data.data);

      console.log(data);
    } catch (err) {
      console.log(err);
    }
  };

  const getMyAvatarList = async () => {
    try {
      let reqdata = {
        address: walletConnection.address,
      };
      let getdata = await getUserAvatar(reqdata);
      setMyavatar(getdata.data);
      console.log(getdata, "getdatagetdata");
    } catch (err) {
      console.log(err);
    }
  };

  console.log(catList, "catList");

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="page_content_layout">
                <div className="avatar_content">
                  <div className="avatar_content_layout">
                    <div class="backdrop canvas_overlay"></div>
                    <div className="avatar_left_column">
                      <h3>Choose your avatar</h3>
                      <div className="avatar_left_column_container">
                        <div className="step_min scroll_step"></div>
                        <div className="step_max scroll_step canvas_overlay_top">
                          <div className="avatar_left_column_mobile_box">
                            <div className="line"></div>
                          </div>
                          <div className="custom_tabbtn_grp">
                            <button
                              className={activeButton === 1 ? "active" : ""}
                              onClick={() => handleButtonClick(1)}
                            >
                              My Assets
                            </button>
                            <button
                              className={activeButton === 2 ? "active" : ""}
                              onClick={() => handleButtonClick(2)}
                            >
                              My Avatars
                            </button>
                            <button
                              className={activeButton === 3 ? "active" : ""}
                              onClick={() => handleButtonClick(3)}
                            >
                              Animations
                            </button>
                          </div>
                          <div className="avatar_left_column_tabcontent">
                            {activeButton === 1 && (
                              <div>
                                <div className="avatar_left_column_top">
                                  <Slider
                                    {...settings}
                                    className="collection_slider"
                                  >
                                    {catList?.length > 0 &&
                                      catList.map((item, index) => (
                                        <div
                                          onClick={() => getNftbyCat(item._id)}
                                        >
                                          <div className="collection_item active">
                                            <div className="collection_item_img">
                                              <img
                                                src={`${config.imgUrl}${item?.Category_Name}/type_1.png`}
                                                alt="Slider"
                                                className="img-fluid"
                                              />
                                            </div>
                                            <p className="collection_item_name">
                                              {item?.Category_Name}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                    <div>
                                      <div className="collection_item active">
                                        <div className="collection_item_img">
                                          <img
                                            src={require("../assets/images/icon_01.png")}
                                            alt="Slider"
                                            className="img-fluid"
                                          />
                                        </div>
                                        <p className="collection_item_name">
                                          Big Poppa Collection
                                        </p>
                                      </div>
                                    </div>
                                  </Slider>
                                </div>
                                <Scrollbars style={{ height: "500px" }}>
                                  <div className="collection_panel">
                                    {NftData &&
                                      NftData?.map((list, index) => {
                                        console.log(list, "listlistlistlist");
                                        return (
                                          <div
                                            className="avatar_collection_card active"
                                            onClick={() => navigation(list)}
                                          >
                                            <div className="avatar_collection_card">
                                              <div className="avatar_collection_card_wrapper">
                                                <div className="avatar_icon_size">
                                                  {/* <svg
                                                  width="5"
                                                  height="11"
                                                  viewBox="0 0 5 11"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  class-name="icon"
                                                >
                                                  <path
                                                    d="M2.37331 0.11546C2.27568 0.0178286 2.11739 0.0178285 2.01976 0.11546L0.428766 1.70645C0.331135 1.80408 0.331135 1.96237 0.428766 2.06C0.526397 2.15763 0.684688 2.15763 0.78232 2.06L2.19653 0.64579L3.61075 2.06C3.70838 2.15763 3.86667 2.15763 3.9643 2.06C4.06193 1.96237 4.06193 1.80408 3.9643 1.70645L2.37331 0.11546ZM2.01976 10.2675C2.11739 10.3651 2.27568 10.3651 2.37331 10.2675L3.9643 8.67651C4.06193 8.57888 4.06193 8.42059 3.9643 8.32296C3.86667 8.22533 3.70838 8.22533 3.61075 8.32296L2.19653 9.73717L0.782319 8.32296C0.684688 8.22533 0.526397 8.22533 0.428766 8.32296C0.331135 8.42059 0.331135 8.57888 0.428766 8.67651L2.01976 10.2675ZM1.94653 0.292236L1.94653 10.0907L2.44653 10.0907L2.44653 0.292236L1.94653 0.292236Z"
                                                    fill="white"
                                                  ></path>
                                                </svg> */}
                                                  <p>M</p>
                                                </div>
                                                <div className="avatar_price">
                                                  <div className="avatar_price_container">
                                                    {list?.my_nft?.Nft_type ==  
                                                      4 && <p>Free</p>}
                                                    {/* <img
                                                    src={require("../assets/images/eth.png")}
                                                    alt="Icon"
                                                    className="img-fluid"
                                                  />
                                                  <p>3</p> */}
                                                  </div>
                                                </div>
                                                <div className="collection_avatar_image">
                                                  <img
                                                    src={
                                                      list?.my_nft?.ipfsimage
                                                    }
                                                    alt="Collection"
                                                    className="img-fluid"
                                                  />
                                                </div>
                                                <div className="avatar_shadow"></div>
                                              </div>
                                            </div>{" "}
                                          </div>
                                        );
                                      })}
                                  </div>
                                </Scrollbars>
                              </div>
                            )}
                            {activeButton === 2 && (
                              <div className="avatar_left_column_top">
                                <Scrollbars style={{ height: "500px" }}>
                                  <div className="collection_panel">
                                    {Myavatar &&
                                      Myavatar.map((item, index) => {
                                        console.log(item, "itemitem");
                                        return (
                                          <div
                                            className={`avatar_collection_card ${
                                              MyselAvatar == item._id
                                                ? "active"
                                                : ""
                                            } `}
                                            onClick={() =>
                                              setMyAvatar(
                                                `${config.imgUrl}Nft/${item?.my_nft?.Filename}`,
                                                item._id
                                              )
                                            }
                                          >
                                            <div className="avatar_collection_card_wrapper">
                                              <div className="avatar_icon_size">
                                                <svg
                                                  width="5"
                                                  z
                                                  height="11"
                                                  viewBox="0 0 5 11"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  class-name="icon"
                                                >
                                                  <path
                                                    d="M2.37331 0.11546C2.27568 0.0178286 2.11739 0.0178285 2.01976 0.11546L0.428766 1.70645C0.331135 1.80408 0.331135 1.96237 0.428766 2.06C0.526397 2.15763 0.684688 2.15763 0.78232 2.06L2.19653 0.64579L3.61075 2.06C3.70838 2.15763 3.86667 2.15763 3.9643 2.06C4.06193 1.96237 4.06193 1.80408 3.9643 1.70645L2.37331 0.11546ZM2.01976 10.2675C2.11739 10.3651 2.27568 10.3651 2.37331 10.2675L3.9643 8.67651C4.06193 8.57888 4.06193 8.42059 3.9643 8.32296C3.86667 8.22533 3.70838 8.22533 3.61075 8.32296L2.19653 9.73717L0.782319 8.32296C0.684688 8.22533 0.526397 8.22533 0.428766 8.32296C0.331135 8.42059 0.331135 8.57888 0.428766 8.67651L2.01976 10.2675ZM1.94653 0.292236L1.94653 10.0907L2.44653 10.0907L2.44653 0.292236L1.94653 0.292236Z"
                                                    fill="white"
                                                  ></path>
                                                </svg>
                                                <p>M</p>
                                              </div>
                                              <div className="collection_avatar_image">
                                                <img
                                                  src={`${config.imgUrl}Nft/${item?.my_nft?.image}`}
                                                  // src={require("../assets/images/collection_02.png")}
                                                  alt="Collection"
                                                  className="img-fluid"
                                                />
                                              </div>
                                              <div className="avatar_shadow"></div>
                                              <div className="collection_avatar_name">
                                                Custom
                                              </div>
                                              {MyselAvatar == item._id ? (
                                                <svg
                                                  width="30"
                                                  height="30"
                                                  viewBox="0 0 30 30"
                                                  fill="none"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  className="avatar_equipped"
                                                  alt="Equipped"
                                                >
                                                  <path
                                                    d="M30 30V0H0L30 30Z"
                                                    fill="#0084FF"
                                                  ></path>
                                                  <path
                                                    d="M15.0012 8.44181L18.9736 12.7857L27.415 3.55493"
                                                    stroke="#DEE0E2"
                                                    stroke-width="2"
                                                  ></path>
                                                </svg>
                                              ) : (
                                                ""
                                              )}
                                            </div>
                                          </div>
                                        );
                                      })}

                                    {/* <div
                                      className="buy_avatar_collection_card"
                                      onClick={() => handleButtonClick(1)}
                                    >
                                      <div className="buy_avatar_card_background">
                                        <img
                                          src={require("../assets/images/silhouette.png")}
                                          alt="Collection"
                                          className="img-fluid"
                                        />
                                        <p>Get avatars</p>
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          className="circle-plus"
                                        >
                                          <path
                                            d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346628 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.998 5.87889 15.1545 3.84524 13.6546 2.34539C12.1548 0.845545 10.1211 0.00203659 8 0ZM8 14.7692C6.66117 14.7692 5.35241 14.3722 4.23922 13.6284C3.12603 12.8846 2.2584 11.8274 1.74605 10.5905C1.2337 9.35356 1.09965 7.99249 1.36084 6.67939C1.62203 5.36629 2.26674 4.16012 3.21343 3.21343C4.16013 2.26674 5.36629 1.62203 6.67939 1.36084C7.99249 1.09965 9.35356 1.2337 10.5905 1.74605C11.8274 2.25839 12.8846 3.12602 13.6284 4.23922C14.3722 5.35241 14.7692 6.66117 14.7692 8C14.7672 9.79468 14.0534 11.5153 12.7843 12.7843C11.5153 14.0534 9.79469 14.7672 8 14.7692ZM11.6923 8C11.6923 8.16321 11.6275 8.31973 11.5121 8.43514C11.3967 8.55055 11.2401 8.61538 11.0769 8.61538H8.61539V11.0769C8.61539 11.2401 8.55055 11.3967 8.43514 11.5121C8.31974 11.6275 8.16321 11.6923 8 11.6923C7.83679 11.6923 7.68027 11.6275 7.56486 11.5121C7.44945 11.3967 7.38462 11.2401 7.38462 11.0769V8.61538H4.92308C4.75987 8.61538 4.60334 8.55055 4.48794 8.43514C4.37253 8.31973 4.30769 8.16321 4.30769 8C4.30769 7.83679 4.37253 7.68026 4.48794 7.56486C4.60334 7.44945 4.75987 7.38461 4.92308 7.38461H7.38462V4.92308C7.38462 4.75987 7.44945 4.60334 7.56486 4.48793C7.68027 4.37253 7.83679 4.30769 8 4.30769C8.16321 4.30769 8.31974 4.37253 8.43514 4.48793C8.55055 4.60334 8.61539 4.75987 8.61539 4.92308V7.38461H11.0769C11.2401 7.38461 11.3967 7.44945 11.5121 7.56486C11.6275 7.68026 11.6923 7.83679 11.6923 8Z"
                                            fill="white"
                                          ></path>
                                        </svg>
                                      </div>
                                    </div> */}
                                  </div>
                                </Scrollbars>
                              </div>
                            )}
                            {activeButton === 3 && (
                              <>
                                <AnimationTypes accessories={animationTypes} />
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="avatar_center_column"></div>
                    <div className="avatar_right_column">
                      {activeButton === 1 && (
                        <>
                          {item.tokenowners_current &&
                            item.tokenowners_current.length > 0 &&
                            item.tokenowners_current.map((itemCur, i) => {
                              return (
                                <>
                                  {itemCur.price > 0 &&
                                    itemCur.balance > 0 &&
                                    itemCur.tokenOwner !=
                                      walletConnection.address && (
                                      <div className="avatar_action_btns">
                                        <Button
                                          className="primary_btn"
                                          onClick={() =>
                                            handleNFTBuy(
                                              item,
                                              BuyOwnerDetailFirst
                                            )
                                          }
                                          variant="contained"
                                        >
                                          Buy
                                          <div className="btn_price_div">
                                            <img
                                              src={require("../assets/images/eth.png")}
                                              alt="Image"
                                              className="img-fluid"
                                            />
                                            <span>{itemCur.price}</span>
                                          </div>
                                        </Button>
                                      </div>
                                    )}
                                  {itemCur.price <= 0 &&
                                    itemCur.balance > 0 &&
                                    itemCur.tokenOwner ==
                                      walletConnection.address && (
                                      <>
                                        <div className="avatar_action_btns">
                                          <Button
                                            className="primary_btn"
                                            variant="contained"
                                          >
                                            Put on sale
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  {itemCur.price > 0 &&
                                    itemCur.balance > 0 &&
                                    itemCur.tokenOwner ==
                                      walletConnection.address && (
                                      <>
                                        <div className="avatar_action_btns">
                                          <Button
                                            className="primary_btn"
                                            onClick={() =>
                                              PutOnSale_Click(
                                                item,
                                                BuyOwnerDetailFirst,
                                                "PC"
                                              )
                                            }
                                            variant="contained"
                                          >
                                            Change Price
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                  {itemCur.price > 0 &&
                                    itemCur.balance > 0 &&
                                    itemCur.tokenOwner ==
                                      walletConnection.address && (
                                      <>
                                        <div className="avatar_action_btns">
                                          <Button
                                            className="primary_btn"
                                            onClick={() =>
                                              CancelOrder_Click(
                                                item,
                                                BuyOwnerDetailFirst
                                              )
                                            }
                                            variant="contained"
                                          >
                                            Cancel Order
                                          </Button>
                                        </div>
                                      </>
                                    )}
                                </>
                              );
                            })}
                        </>
                      )}
                      {activeButton === 2 || activeButton === 3 ? (
                        <>
                          <div className="avatar_action_btns">
                            <Button
                              className="primary_btn"
                              variant="contained"
                              type="button"
                              onClick={() => {
                                navigate("/land");
                              }}
                            >
                              Play Now
                            </Button>
                            <Button
                              className="border_btn"
                              onClick={handleClickCustomize}
                            >
                              Create New avatar
                            </Button>
                          </div>
                        </>
                      ) : null}

                      <div></div>
                    </div>
                  </div>
                  <div
                    className={`mobile_assets_overview ${
                      showAssetsOverviewMobDiv ? "show" : "hide"
                    }`}
                  >
                    <div className="mobile_assets_overview_header">
                      <button
                        class="close_btn"
                        onClick={toggleAssetsOverviewMobFilter}
                      >
                        <svg
                          width="12"
                          height="10"
                          viewBox="0 0 12 10"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M10.8561 9.14373C10.95 9.23853 11.0027 9.36656 11.0027 9.49998C11.0027 9.6334 10.95 9.76143 10.8561 9.85623C10.7606 9.94864 10.6328 10.0003 10.4999 10.0003C10.3669 10.0003 10.2392 9.94864 10.1436 9.85623L5.99988 5.70623L1.85613 9.85623C1.76056 9.94864 1.63282 10.0003 1.49988 10.0003C1.36694 10.0003 1.2392 9.94864 1.14363 9.85623C1.04974 9.76143 0.99707 9.6334 0.99707 9.49998C0.99707 9.36656 1.04974 9.23853 1.14363 9.14373L5.29363 4.99998L1.14363 0.856231C1.0639 0.759083 1.02316 0.635751 1.02932 0.510225C1.03549 0.384699 1.08812 0.265954 1.17699 0.177086C1.26586 0.0882188 1.3846 0.0355836 1.51013 0.0294185C1.63565 0.0232535 1.75899 0.0639994 1.85613 0.143731L5.99988 4.29373L10.1436 0.143731C10.2408 0.0639994 10.3641 0.0232535 10.4896 0.0294185C10.6152 0.0355836 10.7339 0.0882188 10.8228 0.177086C10.9116 0.265954 10.9643 0.384699 10.9704 0.510225C10.9766 0.635751 10.9359 0.759083 10.8561 0.856231L6.70613 4.99998L10.8561 9.14373Z"
                            fill="#ffffff"
                          ></path>
                        </svg>
                      </button>
                    </div>
                    <div className="mobile_assets_overview_content">
                      <div className="avatar_overview">
                        <div className="avatar_name_details">
                          <p>Biggie #0758</p>
                          <div className="avatar_collection_name">
                            <Link to="#">Big Poppa Collection</Link>
                            <svg
                              width="22"
                              height="22"
                              viewBox="0 0 22 22"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M19.4562 18.7L15.8813 19.0438L13.9562 21.5188L11 20.075L7.90625 21.3813L5.775 18.6313L2.6125 18.425L2.475 14.5063L0 12.375L1.85625 9.76251L1.1 6.18751L4.46875 5.15626L5.01875 2.26876H8.73125L11.2062 0.481262L14.025 2.54376H17.3937L17.9438 5.56876L21.0375 7.01251L20.4188 10.2438L22 13.1313L19.4562 15.4V18.7Z"
                                fill="#00AAEF"
                              ></path>
                              <path
                                d="M13.7501 8.31876L15.1251 9.41876L9.9001 15.4L6.25635 12.5125L7.2876 11.2063L9.6251 12.8563L13.7501 8.31876Z"
                                fill="white"
                              ></path>
                            </svg>
                          </div>
                        </div>
                        <div className="avatar_size_details">
                          <div className="avatar_size_info">
                            <p>Size</p>
                            <div className="size_details_container">
                              <svg
                                width="8"
                                height="12"
                                viewBox="0 0 8 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M4.35355 0.646447C4.15829 0.451184 3.84171 0.451184 3.64645 0.646447L0.464466 3.82843C0.269204 4.02369 0.269204 4.34027 0.464466 4.53553C0.659728 4.7308 0.976311 4.7308 1.17157 4.53553L4 1.70711L6.82843 4.53553C7.02369 4.7308 7.34027 4.7308 7.53553 4.53553C7.7308 4.34027 7.7308 4.02369 7.53553 3.82843L4.35355 0.646447ZM3.64645 11.3536C3.84171 11.5488 4.15829 11.5488 4.35355 11.3536L7.53553 8.17157C7.7308 7.97631 7.7308 7.65973 7.53553 7.46447C7.34027 7.2692 7.02369 7.2692 6.82843 7.46447L4 10.2929L1.17157 7.46447C0.97631 7.2692 0.659728 7.2692 0.464466 7.46447C0.269204 7.65973 0.269204 7.97631 0.464466 8.17157L3.64645 11.3536ZM3.5 1L3.5 11L4.5 11L4.5 1L3.5 1Z"
                                  fill="white"
                                ></path>
                              </svg>
                              <span>Medium</span>
                            </div>
                          </div>
                          <div className="stats_seperator"></div>
                          <div className="avatar_size_info">
                            <p>Rarity</p>
                            <div className="size_details_container">
                              <img
                                src={require("../assets/images/open-rarity.png")}
                                alt="Icon"
                                className="img-fluid"
                              />
                              <span>6267</span>
                            </div>
                          </div>
                        </div>
                        <div className="traits_details">
                          <h4>Traits (7)</h4>
                          <div className="traits_info">
                            <p>
                              Background: <span>OLIVE.GREEN</span>
                            </p>
                            <p>
                              Clothing: <span>GREEN VEST + T SHIRT</span>
                            </p>
                            <p>
                              Eye: <span>MEAN RED + BLUE</span>
                            </p>
                            <p>
                              Hat: <span>SILVER HAIR</span>
                            </p>
                            <p>
                              Mouth: <span>FROWN</span>
                            </p>
                            <p>
                              SkinFur: <span>Fur BLUE</span>
                            </p>
                            <p>
                              Species: <span>Species</span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="avatar_scene_renderer">
                  <div className="canvas_wrapper">
                    <LoadEnv />
                  </div>
                </div>
              </div>

              {/* Filter Sidebar */}
              <div
                className={`filter_sidebar_panel ${showDiv ? "show" : "hide"}`}
              >
                <div className="filter_sidebar_header">
                  <h3>Filters</h3>
                  <div className="filter_sidebar_actions">
                    <p class="link_button">Clear all</p>
                    <button className="close_btn" onClick={toggleSidebarFilter}>
                      <svg
                        width="12"
                        height="10"
                        viewBox="0 0 12 10"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10.8561 9.14373C10.95 9.23853 11.0027 9.36656 11.0027 9.49998C11.0027 9.6334 10.95 9.76143 10.8561 9.85623C10.7606 9.94864 10.6328 10.0003 10.4999 10.0003C10.3669 10.0003 10.2392 9.94864 10.1436 9.85623L5.99988 5.70623L1.85613 9.85623C1.76056 9.94864 1.63282 10.0003 1.49988 10.0003C1.36694 10.0003 1.2392 9.94864 1.14363 9.85623C1.04974 9.76143 0.99707 9.6334 0.99707 9.49998C0.99707 9.36656 1.04974 9.23853 1.14363 9.14373L5.29363 4.99998L1.14363 0.856231C1.0639 0.759083 1.02316 0.635751 1.02932 0.510225C1.03549 0.384699 1.08812 0.265954 1.17699 0.177086C1.26586 0.0882188 1.3846 0.0355836 1.51013 0.0294185C1.63565 0.0232535 1.75899 0.0639994 1.85613 0.143731L5.99988 4.29373L10.1436 0.143731C10.2408 0.0639994 10.3641 0.0232535 10.4896 0.0294185C10.6152 0.0355836 10.7339 0.0882188 10.8228 0.177086C10.9116 0.265954 10.9643 0.384699 10.9704 0.510225C10.9766 0.635751 10.9359 0.759083 10.8561 0.856231L6.70613 4.99998L10.8561 9.14373Z"
                          fill="#ffffff"
                        ></path>
                      </svg>
                    </button>
                  </div>
                </div>
                <div className="filter_options_panel">
                  <div className="onsale_option">
                    <div class="form-check form_check_flex">
                      <label class="form-check-label" for="defaultCheck1">
                        On Sale
                      </label>
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                      />
                    </div>
                  </div>
                  <div class="accordion filter_accordian" id="accordionFilter">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingOne">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="false"
                          aria-controls="collapseOne"
                        >
                          Sort By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingOne"
                        data-bs-parent="#accordionFilter"
                      >
                        <div class="accordion-body">
                          <ul className="sorting_list">
                            <li>
                              <span>Lowest price </span>
                            </li>
                            <li>
                              <span>Highest price </span>
                              <img
                                src={require("../assets/images/tick_icon.png")}
                                alt="Image"
                                className="img-fluid"
                              />
                            </li>
                            <li>
                              <span>Name (A-Z) </span>
                            </li>
                            <li>
                              <span>Name (Z-A) </span>
                            </li>
                            <li>
                              <span>Newest </span>
                            </li>
                            <li>
                              <span>Oldest </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingTwo">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          Avatar Size
                        </button>
                      </h2>
                      <div
                        id="collapseTwo"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingTwo"
                        data-bs-parent="#accordionFilter"
                      >
                        <div class="accordion-body">
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="small">
                              Small
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="small"
                            />
                          </div>
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="medium">
                              Medium
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="medium"
                            />
                          </div>
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="large">
                              Large
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="large"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <h3>Collections</h3>
                    <div class="accordion-item ps-2">
                      <h2 class="accordion-header" id="headingThree">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          Minting now
                        </button>
                      </h2>
                      <div
                        id="collapseThree"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingThree"
                        data-bs-parent="#accordionFilter"
                      >
                        <div class="accordion-body">
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="small">
                              Big Poppa Collection
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="small"
                            />
                          </div>
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="medium">
                              Blastheads
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="medium"
                            />
                          </div>
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="large">
                              Elvis Presley
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="large"
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="accordion-item ps-2">
                      <h2 class="accordion-header" id="headingFour">
                        <button
                          class="accordion-button collapsed"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          Official
                        </button>
                      </h2>
                      <div
                        id="collapseFour"
                        class="accordion-collapse collapse"
                        aria-labelledby="headingFour"
                        data-bs-parent="#accordionFilter"
                      >
                        <div class="accordion-body">
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="small">
                              Agorians
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="small"
                            />
                          </div>
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="medium">
                              Captain Laserhawk: A Blood Dragon Remix
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="medium"
                            />
                          </div>
                          <div class="form-check form_check_flex">
                            <label class="form-check-label" for="large">
                              Hell's Kitchen
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              value=""
                              id="large"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
