import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Row, Col, Dropdown } from "react-bootstrap";
import { useSelector } from "react-redux";
import { CollectiblesList_Home } from "../apiAction/nfts.js";
import config from "../config/index.js"
// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Marketplace(props) {
  const walletConnection = useSelector((state) => state.walletConnection);
  const [TokenList, setTokeList] = useState([]);
  useEffect(() => {
    getTokenListCall();
  }, [walletConnection.address]);

  const getTokenListCall = async () => {
    try {
      if (walletConnection.address) {
        var payload = {
          limit: 20,
          // page: page && parseInt(page) > 0 ? page : 0,
          currAddr: walletConnection.address,
          // CatName: name,
          from: "Home",
          type: 3, // normal Nft
        };
        var resp = await CollectiblesList_Home(payload);
        console.log(resp, "respresprespresp");
        setTokeList(resp?.data?.list);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="mar_ket">
                <div className="ban">
                  <h2>Elevate your creations</h2>
                  <p>Harness the power of CATALYSTs</p>
                  <Button className="primary_btn w-auto" variant="contained">
                    Discover CATALYSTs
                  </Button>
                </div>
                <div className="market_nft">
                  <h1>Welcome to the NFT Marketplace</h1>
                  <p className="mb-5">
                    Discover Collections, get Equipment, or find Assets to build
                    your own unique Experiences.
                  </p>
                  <div className="transform">
                    <h5>
                      Build your Experience <span>(7495)</span>
                    </h5>
                    <p>
                      Transform your vision into reality with Unique Characters
                    </p>
                    {/* <div className="fil_flx">
                      <div>All</div>
                      <div>Animals</div>
                      <div>Creatures</div>
                      <div>Humanoids</div>
                      <div className="active">Monsters</div>
                      <div>Robots</div>
                      <div>Living Objects</div>
                      <div>Other Characters</div>
                    </div> */}
                  </div>
                  <Row>
                    {TokenList?.length > 0 &&
                      TokenList.map((nft, i) => {

                        return (
                          <Col lg="4" xl="3" className="d-flex">
                            <div className="nft_box">
                              <a
                                href={`/nft-detail/${nft.tokenCounts}`}
                                className="img_box"
                              >
                                <div className="bg"></div>
                                <img
                                  src={nft.image?`${config.imgUrl}Nft/${nft.image}`:nft.ipfsimage}
                                  alt="img"
                                  className="img-fluid"
                                />
                                <div className="logo_round">
                                  <img
                                    src={require("../assets/images/polygon.png")}
                                    alt="img"
                                    className="img-fluid logo"
                                  />
                                </div>
                              </a>
                              <a href={`/nft-detail/${nft.tokenCounts}`}>
                                {nft?.tokenName}
                              </a>
                              <div className="flx">
                                <div>
                                  <h6>{nft.price} BNB</h6>
                                  {/* <p>{nft.price * 10} USD</p> */}
                                </div>
                                <div className="drp_dwn">
                                  <Dropdown>
                                    <Dropdown.Toggle id="dropdown-basic">
                                      <img
                                        src={require("../assets/images/points.png")}
                                        alt="img"
                                        className="img-fluid"
                                      />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                      <Dropdown.Item href="#/action-1">
                                        Add to wishlist
                                      </Dropdown.Item>
                                      <Dropdown.Item href="#/action-2">
                                        Report
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                              </div>
                            </div>
                          </Col>
                        );
                      })}

                    {/* <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m2.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">A Prisoner</a>
                        <div className="flx">
                          <div>
                            <h6>95.00 SAND</h6>
                            <p>66.55 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m3.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">Hot Girl - Flower Girl</a>
                        <div className="flx">
                          <div>
                            <h6>42.00 SAND</h6>
                            <p>29.42 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m4.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">
                          Vistrea of Lubricanto - Tower of Myths
                        </a>
                        <div className="flx">
                          <div>
                            <h6>5.00 SAND</h6>
                            <p>3.50 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m5.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">Avrora</a>
                        <div className="flx">
                          <div>
                            <h6>25.00 SAND</h6>
                            <p>17.14 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m6.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">Avrora</a>
                        <div className="flx">
                          <div>
                            <h6>25.00 SAND</h6>
                            <p>17.74 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m7.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">Joy</a>
                        <div className="flx">
                          <div>
                            <h6>96.00 SAND</h6>
                            <p>67.24 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col lg="4" xl="3" className="d-flex">
                      <div className="nft_box">
                        <a href="/nft-detail" className="img_box">
                          <div className="bg"></div>
                          <img
                            src={require("../assets/images/nft/m8.avif")}
                            alt="img"
                            className="img-fluid"
                          />
                          <div className="logo_round">
                            <img
                              src={require("../assets/images/polygon.png")}
                              alt="img"
                              className="img-fluid logo"
                            />
                          </div>
                        </a>
                        <a href="/nft-detail">Punk</a>
                        <div className="flx">
                          <div>
                            <h6>1500.00 SAND</h6>
                            <p>1056.69 USD</p>
                          </div>
                          <div className="drp_dwn">
                            <Dropdown>
                              <Dropdown.Toggle id="dropdown-basic">
                                <img
                                  src={require("../assets/images/points.png")}
                                  alt="img"
                                  className="img-fluid"
                                />
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                <Dropdown.Item href="#/action-1">
                                  Add to wishlist
                                </Dropdown.Item>
                                <Dropdown.Item href="#/action-2">
                                  Report
                                </Dropdown.Item>
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
