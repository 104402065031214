//REACT
import { useRef, useEffect } from "react";
//R3F
import { useFrame, extend } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import * as THREE from "three";
import {
  EffectComposer,
  Bloom,
  ToneMapping,
} from "@react-three/postprocessing";
import { ToneMappingMode } from "postprocessing";
import { UnrealBloomPass } from "three-stdlib";
import { OutputPass } from "three/examples/jsm/postprocessing/OutputPass";

//
import modelPath from "../../assets/tool/avatar/stage.glb";
import stageTexture from "../../assets/tool/avatar/floor.jpg";

extend({ UnrealBloomPass, OutputPass });

export default function AvatarStage() {
  const mixer = useRef();
  const { scene, animations } = useGLTF(modelPath);

 
    useEffect(() => {
      if (scene) {
        //TONE MAPPING - FALSE
        scene.children.forEach((skinnedmesh, i) => {
          if (skinnedmesh && skinnedmesh.isObject3D) {
            skinnedmesh.children.forEach((mesh, i) => {
              const MAT = mesh.material;
              if (mesh.name.includes("_2")) {
                MAT.metalness = 0.5;
                MAT.roughness = 0.2;
                const texture = new THREE.TextureLoader().load(stageTexture);
                MAT.map = texture;
                MAT.color.setHex(0x6b6565); 
                MAT.needsUpdate = true;
              }
              else
              {
                MAT.toneMapped = false;
              }
            });
          }
        });

        mixer.current = new THREE.AnimationMixer(scene);
        animations.forEach((clip) => {
          const action = mixer.current.clipAction(clip);
          action.play();
        });
      }
    }, [scene]);

    useFrame((state, delta) => {
      mixer.current && mixer.current.update(delta);
    });

    
  

  return (
    <>
    {/* <EffectComposer>
        <Bloom
          mipmapBlur
          luminanceThreshold={0}
          levels={8}
          intensity={0.7}
        />
        <ToneMapping mode={ToneMappingMode.LINEAR} />
      </EffectComposer> */}
    {scene ? (
      
      <primitive
        name={"stage"}
        object={scene}
        scale={[60, 60, 60]}
        position={[0, -50, 0]}
      />
    ):null};
    </>
  ) 
    }