import { SET_PAN, SET_SETTINGS, SET_CUSTOM_MEN,SET_AVATAR } from "../../constant/toolpage/const";

const setSettings = (animationInfo) => {
  return {
    type: SET_SETTINGS,
    payload: animationInfo
  };
};

const setPanning = (panInfo) => {
    return {
      type: SET_PAN,
      payload: panInfo?.info
    };
};

const setCustomMen = (menInfo) => {
    return {
      type: SET_CUSTOM_MEN,
      payload: menInfo?.info
    };
};

const setAvatar = (avatarInfo) => {
  return {
    type: SET_AVATAR,
    payload: avatarInfo?.info
  };
};

export { setSettings, setPanning, setCustomMen, setAvatar };