import React, { useMemo, useEffect, useState, useRef } from "react";
//R3F
import { useGraph, useFrame } from "@react-three/fiber";
import { useGLTF, useAnimations } from "@react-three/drei";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
import * as THREE from "three";

//ANIMATIONS
import AvatarAnimations from "../config/AvatarAnimations.json";

export default function AvatarModel({
  group,
  materials,
  nodes,
  settings,
  menInfo,
}) {
  //MODEL INFO
  const { option, hair, shirt, pant, shoe } = menInfo;

  //RETARGET - ANIMATION
  const animationGLB = useGLTF(
    require("../../assets/tool/avatar/" +
      settings.model +
      "/" +
      AvatarAnimations?.[settings?.model]?.[settings?.animationType]?.path)
  );
  const { actions } = useAnimations(animationGLB.animations, group);
  //console.log("actions:=", actions);

  useEffect(() => {
    actions?.[
      AvatarAnimations[settings.model][settings.animationType].actionName
    ]
     ?.reset()
      ?.fadeIn(0.5)
      ?.play();
    return () =>
      actions?.[
        AvatarAnimations[settings.model][settings.animationType].actionName
      ]?.fadeOut(0.5);
  }, [settings?.animationType]);

  return (
    <>
      <group
        name={"avatar"}
        ref={group}
        dispose={null}
        scale={[0.7, 0.7, 0.7]}
        rotation={[1.59, 0, 0]}
        position={[0, -50, 0]}
      >
        <skinnedMesh
          name="Avator_Final001_13"
          geometry={nodes.Avator_Final001_13.geometry}
          material={materials["face_skin.001"]}
          skeleton={nodes.Avator_Final001_13.skeleton}
        />

        <skinnedMesh
          name="Avator_Final001_4"
          geometry={nodes.Avator_Final001_4.geometry}
          material={materials["Hair.001"]}
          skeleton={nodes.Avator_Final001_4.skeleton}
        />
        {/*Hair*/}
        <skinnedMesh
          name="Avator_Final001_7"
          geometry={hair?.geo}
          material={hair?.mat}
          skeleton={nodes.Avator_Final001_7.skeleton}
        />

        <skinnedMesh
          name="Avator_Final001_5"
          geometry={nodes.Avator_Final001_5.geometry}
          material={materials["Eyebrows.001"]}
          skeleton={nodes.Avator_Final001_5.skeleton}
        />
        <skinnedMesh
          name="Avator_Final001_6"
          geometry={nodes.Avator_Final001_6.geometry}
          material={materials["Eye.001"]}
          skeleton={nodes.Avator_Final001_6.skeleton}
        />

        {/*Left hand skin*/}
        <skinnedMesh
          name="Avator_Final001_9"
          geometry={shirt?.leftHand?.geo}
          material={shirt?.leftHand?.mat}
          skeleton={nodes.Avator_Final001_9.skeleton}
        />
        {/*Right hand skin*/}
        <skinnedMesh
          name="Avator_Final001_12"
          geometry={shirt?.rightHand?.geo}
          material={shirt?.rightHand?.mat}
          skeleton={nodes.Avator_Final001_12.skeleton}
        />

        {/*Shirt*/}
        {shirt?.shirtMesh?.map((mesh) => (
          <skinnedMesh
            name="Avator_Final001_2"
            geometry={mesh?.geo}
            material={mesh?.mat}
            skeleton={nodes.Avator_Final001_2.skeleton}
          />
        ))}
        {/*Jacket*/}
        {shirt?.jacketMesh?.map((mesh) => (
          <skinnedMesh
            name="Avator_Final001_3"
            geometry={mesh?.geo}
            material={mesh?.mat}
            skeleton={nodes.Avator_Final001_3.skeleton}
          />
        ))}

        {/*Pant*/}
        {pant?.pantMesh?.map((mesh) => (
          <skinnedMesh
            name="Avator_Final001_8"
            geometry={mesh?.geo}
            material={mesh?.mat}
            skeleton={nodes.Avator_Final001_8.skeleton}
          />
        ))}

        {/*Knee skin - always false*/}
        <skinnedMesh
          visible={false}
          name="Avator_Final001_10"
          geometry={nodes.Avator_Final001_10.geometry}
          material={materials["Knee_skin.001"]}
          skeleton={nodes.Avator_Final001_10.skeleton}
        />

        {/*shoe*/}
        {shoe?.shoeMesh?.map((mesh) => (
          <skinnedMesh
            name="Avator_Final001_1"
            geometry={mesh?.geo}
            material={mesh?.mat}
            skeleton={nodes.Avator_Final001_1.skeleton}
          />
        ))}

        <primitive object={nodes.mixamorigHips} />
      </group>
    </>
  );
}
