import { SET_PAN, SET_SETTINGS, SET_CUSTOM_MEN, SET_AVATAR } from "../constant/toolpage/const";
//CONFIG
import config from "../../config/index";

const initialState = {
  ui:{
      option:"default",
      download : false,
      avatarModel: "",
      avatarImage:""
  },
  settings:{
      animationType : "idle",
      model : "men",
      customize : "custom-avatar"
  },
  myAvatar:{
    glb : config?.myAvatar
  },
  men:{
      option : "hair",
      hair : {
        type : "type_1",
        color : "0x000000"
      },
      shirt : {
        type : "type_1"
      },
      pant : {
        type : "type_1"
      },
      shoe : {
        type : "type_1"
      }
  },
  women:{
  }
};

const avatarTool = (state = initialState, action) => {
  switch (action.type) {
    case SET_PAN:
      return {
        ...state,
        ui : action.payload
      }; 
    case SET_SETTINGS:
      return {
        ...state,
        settings : action.payload
      };  
    case SET_CUSTOM_MEN:
      return {
        ...state,
        men : action.payload
      };
    case SET_AVATAR:
      return {
        ...state,
        myAvatar : action.payload
      };
    default:
      return state;
  }
};

export default avatarTool;
