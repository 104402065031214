import Web3 from "web3";
import store from "../store";

import config from "../config/index";

export async function connection() {
  var currentProvider = store.getState();

  var connect = {
    web3: "",
    address: "",
    network: config.networkId,
    provider: "",
    connect: "no"
  };

  var provider = config.rpcUrl;
  var address = "";
  var connected = "no";
  var network = config.networkId;
  if (
    currentProvider &&
    currentProvider.walletConnection &&
    currentProvider.walletConnection.provider &&
    currentProvider.walletConnection.provider !== ""
  ) {
    provider = currentProvider.walletConnection.provider;
    address = currentProvider.walletConnection.address;
    connected = "yes";
    network = currentProvider.walletConnection.network;
  }

  try {
    var web3 = new Web3(provider);
    connect.network = network;
    connect.web3 = web3;
    connect.address = address;
    connect.provider = provider;
    connect.connect = connected;
  } catch (err) {}
  return connect;
}

//without metamask connection
export function readWeb3() {
  var web3 = new Web3(config.rpcUrl);
  return web3;
}
