import { useEffect, useRef } from "react";

//R3F
import { useGLTF, useAnimations } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

//ANIMATIONS
import AvatarAnimations from "../config/AvatarAnimations.json";

export default function MyAvatarModel({ group,settings,scene,nodes }) {
  //RETARGET - ANIMATION
  const animationGLB = useGLTF(
    require("../../assets/tool/avatar/" +
      settings.model +
      "/" +
      AvatarAnimations?.[settings?.model]?.[settings?.animationType]?.path)
  );
  const { actions } = useAnimations(animationGLB.animations, scene);

  const mixer = useRef();

  useEffect(() => {
    mixer.current = new THREE.AnimationMixer(scene);

    const action = mixer.current.clipAction(
      actions[AvatarAnimations[settings.model][settings.animationType].actionName]?._clip
    );
    action.play();
  }, [scene,settings.animationType]);

  useFrame((state, delta) => {
    mixer.current && mixer.current.update(delta);
  });

  return (
    <>
      {scene ? (
         <group name="my-avatar" ref={group} dispose={null} >
          <primitive object={scene} />
         </group>
      ) : null}
    </>
  );
}
