import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useRoutes,
  Navigate
} from "react-router-dom";

import Avatar from "./pages/avatar.js";
import CustomizeAvatar from "./pages/customize-avatar.js";
import Map from "./pages/map.js";
import Viewer from "./pages/viewer.js";
import Land from "./pages/land.js";

import Login from "./pages/login.js";
import Register from "./pages/register.js";
import ForgotPassword from "./pages/forgot-password.js";
import ResetPassword from "./pages/reset-password.js";

import { Provider } from "react-redux";
import store from "./store.js";

import { publicProvider } from "wagmi/providers/public";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";

import { createConfig, configureChains, WagmiConfig } from "wagmi";
import { infuraProvider } from "wagmi/providers/infura";
import { bsc, bscTestnet, sepolia, mainnet } from "wagmi/chains";
import Marketplace from "./pages/marketplace.js";
import Nftdetail from "./pages/nft-detail.js";
import Accessories from "./pages/accessories.js";
import Nft from "./pages/nft.js";
import SampleCreate from "./pages/sampleCreate.js";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [bsc, bscTestnet],
  [
    infuraProvider({
      apiKey: "cb89ebb21cdccb2e1b591e189e27706a"
    }),
    publicProvider()
  ]
);
const wconfigInitial = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: "cb89ebb21cdccb2e1b591e189e27706a",
        version: 2
      }
    })
  ],
  publicClient,
  webSocketPublicClient
});

const App = () => {
  const routes = useRoutes([
    { path: "/*", element: <Navigate to="/avatar" /> },
    { path: "/avatar", element: <Avatar /> },
    { path: "/customize-avatar", element: <CustomizeAvatar /> },
    { path: "/viewer", element: <Viewer /> },
    { path: "/land", element: <Land /> },
    { path: "/map", element: <Map /> },
    { path: "/marketplace", element: <Marketplace /> },
    { path: "/nft-detail/:tokenidval", element: <Nftdetail /> },
    { path: "/accessories", element: <Accessories /> },
    { path: "/nft", element: <Nft /> },
    { path: "/create", element: <SampleCreate /> },
    { path: "/login", element: <Login /> },
    { path: "/register", element: <Register /> },
    { path: "/forgot-password", element: <ForgotPassword /> },
    { path: "/reset-password", element: <ResetPassword /> }
  ]);
  return routes;
};

const AppWrapper = () => {
  return (
    <Router basename="/">
      <Provider store={store}>
        <WagmiConfig config={wconfigInitial}>
          <ToastContainer />
          <App />
        </WagmiConfig>
      </Provider>
    </Router>
  );
};

export default AppWrapper;
