import { Suspense } from "react";
import { useSelector } from "react-redux";

//COMPONENTS
import Avatar from "./Avatar";
import MyAvatar from "./MyAvatar";
import AvatarStage from "./AvatarStage";
import AvatarStageGlow from "./AvatarStageGlow";
import AvatarPanning from "./AvatarPanning";
import Loader from "../Loader";


export default function AvatarScene() {
  //STATE
  const { settings,myAvatar } = useSelector((state) => state.avatarTool);
  console.log("settings:=",settings?.customize,myAvatar?.glb)
  return (
    <>
      <AvatarStageGlow />
      <AvatarStage />
      <Suspense fallback={<Loader />}>
        {(settings?.customize==="custom-avatar")?(
        <Avatar />
        ):(<MyAvatar modelPath={myAvatar?.glb}/>)}
      </Suspense>
      <AvatarPanning />
    </>
  );
}
