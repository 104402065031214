//R3F
import { OrbitControls } from "@react-three/drei";

function OrbitCtrls() {
  return (
    <OrbitControls
      makeDefault
      minPolarAngle={1.5}
      maxPolarAngle={1.5}
      enableDamping={true}
      enablePan={false}
      minDistance={120}
      maxDistance={190}
    />
  );
}

export default OrbitCtrls;
