export default function Lights() {
  return (
    <>
      <directionalLight
        intensity={5}
        color={'#FFFFED'}
        castShadow
        shadow-bias={-0.0004}
        position={[-20, 100, 20]}
        shadow-camera-top={100}
        shadow-camera-right={100}
        shadow-camera-bottom={-100}
        shadow-camera-left={-100}
      />
      <ambientLight intensity={0.2} />
    </>
  )
}
