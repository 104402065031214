//REACT
import { useRef } from "react";
//R3F
import { useFrame } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
//
import loaderModel from "../assets/tool/avatar/loader.glb";

export default function Loader() {
  const mixer = useRef();
  const { scene } = useGLTF(loaderModel);

  useFrame((state, delta) => {
    mixer.current.rotateY(delta);
  });
  return (
    <>
      {scene ? (
        <primitive name="loader" ref={mixer} object={scene} scale={[1, 1, 1]} position={[0, 0, 0]} />
      ) : null}
    </>
  );
}
