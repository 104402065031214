import React, { useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { useSelector, useDispatch } from "react-redux";

export default function CharacterModel(props) {
  //STATE
  const avatarTool = useSelector((state) => state.avatarTool);
 // const { scene, nodes, materials } = useGLTF(require("../assets/tool/land/test.glb"));
 const { scene, nodes, materials } = useGLTF(avatarTool?.myAvatar?.glb);
  //console.log(nodes);

  // for (const material in materials) {
  //   materials[material].metalness = -2;
  //   materials[material].roughness = 1;
  // }
  //scale={0.021} position={[0,-0.85,0]}
  return (
    <>
      <group
        name="landCharModel"
        scale={0.021}
        position={[0, 0.2, 0]}
        // rotation={[1.59, 0, 0]}
      >
        {/* <primitive object={nodes.mixamorigHips} /> */}
        <primitive object={scene} />
      </group>
    </>
  );
}
useGLTF.preload(require("../assets/tool/land/test.glb"));
