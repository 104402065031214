import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { Row, Col } from "react-bootstrap";
import { Scrollbars } from "react-custom-scrollbars";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Map(props) {
  const [showDiv, setShowDiv] = useState(false);

  const toggleSidebarFilter = () => {
    setShowDiv(!showDiv);
  };

  const [showPlayFiltersDiv, setShowPlayFiltersDiv] = useState(false);
  const togglePlayFiltersDiv = () => {
    setShowPlayFiltersDiv(!showPlayFiltersDiv);
  };

  const [showBuyLandFiltersDiv, setShowBuyLandFiltersDiv] = useState(false);
  const toggleBuyLandFiltersDiv = () => {
    setShowBuyLandFiltersDiv(!showBuyLandFiltersDiv);
  };

  const [showPartnersDiv, setShowPartnersDiv] = useState(false);
  const togglePartnersDiv = () => {
    setShowPartnersDiv(!showPartnersDiv);
  };

  const [showExperienceDiv, setShowExperienceDiv] = useState(false);
  const toggleExperienceDiv = () => {
    setShowExperienceDiv(!showExperienceDiv);
  };

  const [showExperienceDetailsDiv, setShowExperienceDetailsDiv] =
    useState(false);
  const toggleExperienceDetailsDiv = () => {
    setShowExperienceDetailsDiv(!showExperienceDetailsDiv);
  };

  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <div className="page_map">
                <div className="tiled_map_container">
                  <div
                    className={`map_filter_sidebar no_select ${
                      showDiv ? "show" : "closed"
                    }`}
                  >
                    <div id="map_sidebar" className="map_sidebar_wrapper me-2">
                      <div className="map_filters_wrapper">
                        <h3>Map Mode</h3>
                        <div className="map_play_section">
                          <button
                            className="map_filter_btn"
                            onClick={togglePlayFiltersDiv}
                          >
                            <img
                              src={require("../assets/images/icon_play.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <span>Play</span>
                          </button>
                          {showPlayFiltersDiv && (
                            <div className="map_mode_filter">
                              <div
                                class="accordion filter_accordian"
                                id="accordionFilterPlay"
                              >
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Featured
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionFilterPlay"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="featured"
                                        >
                                          Featured
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="featured"
                                          name="sortby"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="toprated"
                                        >
                                          Top Rated
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="toprated"
                                          name="sortby"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="new"
                                        >
                                          New
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="new"
                                          name="sortby"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      Progress
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseTwo"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionFilterPlay"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="visited"
                                        >
                                          Visited
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="visited"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="notvisited"
                                        >
                                          Not Visited
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="notvisited"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2
                                    class="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      Premium
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionFilterPlay"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="premium"
                                        >
                                          Premium Experience
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="premium"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="map_buy_section">
                          <button
                            className="map_filter_btn"
                            onClick={toggleBuyLandFiltersDiv}
                          >
                            <img
                              src={require("../assets/images/icon_buyland.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <span>Buy Land</span>
                          </button>
                          {showBuyLandFiltersDiv && (
                            <div className="map_mode_filter">
                              <div
                                class="accordion filter_accordian"
                                id="accordionFilterBuyLand"
                              >
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Lower Price
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionFilterBuyLand"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="lowprice"
                                        >
                                          Lower Price
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="lowprice"
                                          name="sortby"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="highprice"
                                        >
                                          Higher Price
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="highprice"
                                          name="sortby"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      Currency
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseTwo"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionFilterBuyLand"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="ETH"
                                        >
                                          ETH
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="ETH"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="SAND"
                                        >
                                          SAND
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="SAND"
                                        />
                                      </div>

                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="USDC"
                                        >
                                          USDC
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="USDC"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2
                                    class="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      Land Type
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionFilterBuyLand"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="premium"
                                        >
                                          Premium
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="premium"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="regular"
                                        >
                                          Regular
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="regular"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2
                                    class="accordion-header"
                                    id="headingThree"
                                  >
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseThree"
                                      aria-expanded="false"
                                      aria-controls="collapseThree"
                                    >
                                      Blockchain
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseThree"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingThree"
                                    data-bs-parent="#accordionFilterBuyLand"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="ethereum"
                                        >
                                          Ethereum
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="ethereum"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="polygon"
                                        >
                                          Polygon
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="polygon"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="map_layers_section">
                        <h3>Markers</h3>
                        <div className="map_partners_sec">
                          <div
                            class="form-check form-switch mapmarkers_toggle_sec form_switch_custom"
                            onClick={togglePartnersDiv}
                          >
                            <label
                              class="form-check-label"
                              for="flexSwitchCheckDefault"
                            >
                              <img
                                src={require("../assets/images/icon_partners.png")}
                                alt="Icon"
                                className="img-fluid"
                              />
                              <span>Partners</span>
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault"
                            />
                          </div>
                          {showPartnersDiv && (
                            <div className="map_mode_filter">
                              <div
                                class="accordion filter_accordian"
                                id="accordionFilterPlay"
                              >
                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingTwo">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseTwo"
                                      aria-expanded="false"
                                      aria-controls="collapseTwo"
                                    >
                                      Category
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseTwo"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingTwo"
                                    data-bs-parent="#accordionFilterPlay"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="nft"
                                        >
                                          NFT Collection
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="nft"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="media"
                                        >
                                          Media
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="media"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="sport"
                                        >
                                          Sport
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="sport"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="gaming"
                                        >
                                          Gaming
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="gaming"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="art"
                                        >
                                          Art
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="checkbox"
                                          value=""
                                          id="art"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="accordion-item">
                                  <h2 class="accordion-header" id="headingOne">
                                    <button
                                      class="accordion-button collapsed"
                                      type="button"
                                      data-bs-toggle="collapse"
                                      data-bs-target="#collapseOne"
                                      aria-expanded="false"
                                      aria-controls="collapseOne"
                                    >
                                      Name
                                    </button>
                                  </h2>
                                  <div
                                    id="collapseOne"
                                    class="accordion-collapse collapse"
                                    aria-labelledby="headingOne"
                                    data-bs-parent="#accordionFilterPlay"
                                  >
                                    <div class="accordion-body">
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="time"
                                        >
                                          TIME Magazine
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="time"
                                          name="sortby"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="care"
                                        >
                                          Care Bears
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="care"
                                          name="sortby"
                                        />
                                      </div>
                                      <div class="form-check form_check_flex">
                                        <label
                                          class="form-check-label"
                                          for="walking"
                                        >
                                          The Walking Dead
                                        </label>
                                        <input
                                          class="form-check-input"
                                          type="radio"
                                          id="walking"
                                          name="sortby"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="map_myland_sec">
                          <div class="form-check form-switch mapmarkers_toggle_sec form_switch_custom">
                            <label
                              class="form-check-label"
                              for="flexSwitchCheckDefault1"
                            >
                              <img
                                src={require("../assets/images/icon_mylands.png")}
                                alt="Icon"
                                className="img-fluid"
                              />
                              <span>My Land</span>
                            </label>
                            <input
                              class="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckDefault1"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mapsidebar_buttons_wrapper">
                      <button
                        className="mapsidebar_button no_select custom_icon_btn"
                        onClick={toggleSidebarFilter}
                      >
                        <img
                          src={require("../assets/images/icon-sidebar.png")}
                          alt="Icon"
                          className="img-fluid mapsidebar_setting_icon"
                        />
                        <img
                          src={require("../assets/images/icon-arrow.png")}
                          alt="Icon"
                          className="img-fluid mapsidebar_close_icon"
                        />
                      </button>
                    </div>
                    {/* Search result tags */}
                    {showPlayFiltersDiv && (
                      <div className="map_tags_wrapper">
                        <div className="map_tag">
                          <span>Showing 100 of 508 results</span>
                        </div>
                        <div className="map_tag">
                          <span>Single Player</span>
                          <img
                            src={require("../assets/images/icon_close.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                        </div>
                        <div className="map_tag">
                          <span>Free to Play</span>
                          <img
                            src={require("../assets/images/icon_close.png")}
                            alt="Icon"
                            className="img-fluid"
                          />
                        </div>
                      </div>
                    )}
                  </div>

                  {/* Map Experiences List */}
                  {showPlayFiltersDiv && (
                    <div
                      className={`map_experiences_list ${
                        showExperienceDiv ? "hide" : ""
                      }`}
                    >
                      <button
                        className="custom_icon_btn no_select show_experiences_button"
                        onClick={toggleExperienceDiv}
                      >
                        <img
                          src={require("../assets/images/icon-arrow.png")}
                          alt="Icon"
                          className="img-fluid ex_arrow_icon"
                        />
                        <img
                          src={require("../assets/images/icon_search.png")}
                          alt="Icon"
                          className="img-fluid ex_search_icon"
                        />
                      </button>
                      <div className="map_experiences_panel">
                        <div className="map_experiences_search">
                          <div className="search_field">
                            <button className="experiences_search_button">
                              <img
                                src={require("../assets/images/icon_search.png")}
                                alt="Icon"
                                className="img-fluid"
                              />
                            </button>
                            <input
                              type="text"
                              placeholder="Search an experience"
                              className="search_input"
                            />
                          </div>
                        </div>

                        <div className="map_experience_list_container">
                          <div className="game_jam">
                            <img
                              src={require("../assets/images/gamejam.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <span className="end_date">
                              Open until April 22nd
                            </span>
                            <div className="game_jam_description">
                              <div className="tags">
                                <span className="tag">Builders' Challenge</span>
                              </div>
                              <h2>Build, Publish and Earn</h2>
                              <p>
                                Publish your experience, engage users and earn
                                your share of up to <b>ONE MILLION SAND</b>
                              </p>
                              <Link to="" className="custom_button xsmall">
                                Get Started
                              </Link>
                            </div>
                          </div>

                          <div
                            className="experience_div"
                            onClick={toggleExperienceDetailsDiv}
                          >
                            <div className="experience_div_info">
                              <ul className="tags">
                                <li className="tag ugc">UGC</li>
                              </ul>
                              <p className="experience_name">
                                Action City - Presented by Lionsgate
                              </p>
                              <Link to="" className="experience_user_tag">
                                @TSBLionsgate
                              </Link>
                              <div className="experience_stats">
                                <p>33 Quests</p>
                                <img
                                  src={require("../assets/images/icon_star.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                                <p>5.0</p>
                                <img
                                  src={require("../assets/images/icon_views.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                                <p>428</p>
                              </div>
                              <div className="map_experience_cta">
                                <button className="custom_icon_btn xs_btn">
                                  <img
                                    src={require("../assets/images/icon_center.png")}
                                    alt="Icon"
                                    className="img-fluid"
                                  />
                                </button>
                                <button
                                  className="map_experience_event custom_icon_btn xs_btn"
                                  data-tooltip-id="event"
                                  data-tooltip-content="This experience is part of an event"
                                >
                                  <img
                                    src={require("../assets/images/calendar_icon.png")}
                                    alt="Icon"
                                    className="img-fluid"
                                  />
                                  <span className="event_notification">1</span>
                                </button>
                                <ReactTooltip
                                  id="event"
                                  className="custom_tooltip"
                                />
                              </div>
                            </div>
                            <div className="experience_preview_cta">
                              <img
                                src={require("../assets/images/img_01.jpg")}
                                alt="Experience Preview"
                                className="img-fluid"
                              />
                            </div>
                          </div>

                          <div className="experience_div experience_premium_div">
                            <div className="experience_preview_cta">
                              <img
                                src={require("../assets/images/img_01.jpg")}
                                alt="Experience Preview"
                                className="img-fluid"
                              />
                            </div>
                            <div className="experience_div_info">
                              <ul className="tags">
                                <li className="tag ugc">UGC</li>
                                <li className="tag premium">Premium</li>
                              </ul>
                              <p className="experience_name">
                                Action City - Presented by Lionsgate
                              </p>
                              <Link to="" className="experience_user_tag">
                                @TSBLionsgate
                              </Link>
                              <div className="experience_stats">
                                <p>33 Quests</p>
                                <img
                                  src={require("../assets/images/icon_star.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                                <p>5.0</p>
                                <img
                                  src={require("../assets/images/icon_views.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                                <p>428</p>
                              </div>
                              <div className="map_experience_cta">
                                <button className="custom_icon_btn xs_btn">
                                  <img
                                    src={require("../assets/images/icon_center.png")}
                                    alt="Icon"
                                    className="img-fluid"
                                  />
                                </button>
                              </div>
                            </div>
                          </div>

                          <div className="experience_div">
                            <div className="experience_div_info">
                              <ul className="tags">
                                <li className="tag ugc">UGC</li>
                              </ul>
                              <p className="experience_name">
                                Action City - Presented by Lionsgate
                              </p>
                              <Link to="" className="experience_user_tag">
                                @TSBLionsgate
                              </Link>
                              <div className="experience_stats">
                                <p>33 Quests</p>
                                <img
                                  src={require("../assets/images/icon_star.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                                <p>5.0</p>
                                <img
                                  src={require("../assets/images/icon_views.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                                <p>428</p>
                              </div>
                              <div className="map_experience_cta">
                                <button className="custom_icon_btn xs_btn">
                                  <img
                                    src={require("../assets/images/icon_center.png")}
                                    alt="Icon"
                                    className="img-fluid"
                                  />
                                </button>
                                <button
                                  className="map_experience_event custom_icon_btn xs_btn"
                                  data-tooltip-id="event"
                                  data-tooltip-content="This experience is part of an event"
                                >
                                  <img
                                    src={require("../assets/images/calendar_icon.png")}
                                    alt="Icon"
                                    className="img-fluid"
                                  />
                                  <span className="event_notification">1</span>
                                </button>
                                <ReactTooltip
                                  id="event"
                                  className="custom_tooltip"
                                />
                              </div>
                            </div>
                            <div className="experience_preview_cta">
                              <img
                                src={require("../assets/images/img_01.jpg")}
                                alt="Experience Preview"
                                className="img-fluid"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div
                    className={`map_controls ${
                      showDiv ? "" : "mapsidebar_closed"
                    }`}
                  >
                    <div className="map_controls_btn">
                      <button className="custom_icon_btn">
                        <img
                          src={require("../assets/images/icon_plus.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                      </button>
                      <button className="custom_icon_btn">
                        <img
                          src={require("../assets/images/icon_minus.png")}
                          alt="Icon"
                          className="img-fluid"
                        />
                      </button>
                    </div>
                  </div>

                  <div className="map_leaflet_container">
                    <img
                      src={require("../assets/images/map_bg.png")}
                      alt="Map"
                      className="img-fluid"
                    />
                  </div>
                </div>

                {/* Experience Detail Page */}
                {showExperienceDetailsDiv && (
                  <div
                    className={`map_experience_detail_wrapper ${
                      showExperienceDetailsDiv ? "" : "hide"
                    }`}
                  >
                    <button
                      className="custom_icon_btn close_btn"
                      onClick={toggleExperienceDetailsDiv}
                    >
                      <img
                        src={require("../assets/images/icon_close.png")}
                        alt="Icon"
                        className="img-fluid"
                      />
                    </button>
                    <div className="map_experience_detail_body">
                      <div className="map_experience_detail_banner">
                        <img
                          src={require("../assets/images/img_01.jpg")}
                          alt="Map"
                          className="img-fluid"
                        />
                      </div>
                      <div className="map_experience_detail_info">
                        <div className="map_experience_detail_info_header">
                          <div className="tags">
                            <div className="tag ugc">UGC</div>
                          </div>
                          <div className="map_exp_coordinates d-flex align-center flex-row">
                            <img
                              src={require("../assets/images/icon_mappin.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <p className="mb-0">-194, -161</p>
                          </div>
                          <div className="buttons_container">
                            <button className="custom_icon_btn sm_btn">
                              <img
                                src={require("../assets/images/icon_center.png")}
                                alt="Icon"
                                className="img-fluid"
                              />
                            </button>
                          </div>
                        </div>

                        <div className="map_experience_detail_info_resume">
                          <h3>Cosmic Chaos</h3>
                          <div className="experience_stats">
                            <p>5 Quests</p>
                            <img
                              src={require("../assets/images/icon_star_grey.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <p>4.7</p>
                            <img
                              src={require("../assets/images/icon_play_gry.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <p>1K</p>
                            <img
                              src={require("../assets/images/icon_multiplayer.png")}
                              alt="Icon"
                              className="img-fluid"
                            />
                            <p>Multiplayer</p>
                          </div>
                          <p className="experience_desc">
                            Team up in this endless co-op arcade shooter!
                            Control blue and red spaceships, dodging traps and
                            blasting enemies for high scores.
                          </p>
                        </div>

                        <div className="map_experience_detail_creator">
                          <div className="owner_wrapper">
                            <div className="owner_info">
                              <p>OWNER</p>
                              <Link to="">@Sandja</Link>
                            </div>
                            <div className="owner_socials">
                              <a href="#" target="_blank">
                                <img
                                  src={require("../assets/images/icon_discord.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </a>
                              <a href="#" target="_blank">
                                <img
                                  src={require("../assets/images/icon_twitter.png")}
                                  alt="Icon"
                                  className="img-fluid"
                                />
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="map_experience_rating">
                          <p>PLAY TO RATE THE EXPERIENCE</p>
                          <div className="star_rating_wrapper">
                            <label class="star_rating_star">
                              <input
                                disabled="disabled"
                                type="radio"
                                class="star_rating_checkbox"
                                value="1"
                              />
                              ★
                            </label>
                            <label class="star_rating_star">
                              <input
                                disabled="disabled"
                                type="radio"
                                class="star_rating_checkbox"
                                value="1"
                              />
                              ★
                            </label>
                            <label class="star_rating_star">
                              <input
                                disabled="disabled"
                                type="radio"
                                class="star_rating_checkbox"
                                value="1"
                              />
                              ★
                            </label>
                            <label class="star_rating_star">
                              <input
                                disabled="disabled"
                                type="radio"
                                class="star_rating_checkbox"
                                value="1"
                              />
                              ★
                            </label>
                            <label class="star_rating_star">
                              <input
                                disabled="disabled"
                                type="radio"
                                class="star_rating_checkbox"
                                value="1"
                              />
                              ★
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="experience_details_footer_wrapper">
                      <div className="experience_details_footer_cta">
                        <Button className="primary_btn" variant="contained">
                          Play
                        </Button>
                        <Button className="border_btn">
                          <Link to="">Details</Link>
                        </Button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
