import { useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useSelector, useDispatch } from "react-redux";
//STATE ACTIONS
import { setCustomMen } from "../../redux/action/toolpage/action";
//COMPONENTS
import TypesFilter from "./TypesFilter";

export default function ShirtTypes(props) {
  let { accessories } = props;

  //STATE
  const avatarTool = useSelector((state) => state.avatarTool);
  const dispatch = useDispatch();
  const [type,setType] = useState(avatarTool?.men?.shirt?.type);

  const chooseStyle = (type) => {
    setType(type);
    let Details = {
      info: avatarTool?.men,
    };
    Details.info.option = "shirt";
    Details.info.shirt.type = type;
    dispatch(setCustomMen(Details));
  };

  return (
    <div>
      <div className="avatar_left_column_top">
        <TypesFilter />
      </div>
      <Scrollbars style={{ height: "500px" }}>
        <div className="collection_panel">
        {accessories &&
            accessories.map((item, index) => {
              const myArray = item?.my_nft?.image?.split(".");

              return (
                <div
                  className={`avatar_collection_card ${
                    type === myArray[0] && "active"
                  }`}
                  onClick={() => chooseStyle(myArray[0])}
                >
                  <div className="avatar_collection_card_wrapper">
                    <div className="avatar_icon_size">
                      <svg
                        width="5"
                        height="11"
                        viewBox="0 0 5 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        class-name="icon"
                      >
                        <path
                          d="M2.37331 0.11546C2.27568 0.0178286 2.11739 0.0178285 2.01976 0.11546L0.428766 1.70645C0.331135 1.80408 0.331135 1.96237 0.428766 2.06C0.526397 2.15763 0.684688 2.15763 0.78232 2.06L2.19653 0.64579L3.61075 2.06C3.70838 2.15763 3.86667 2.15763 3.9643 2.06C4.06193 1.96237 4.06193 1.80408 3.9643 1.70645L2.37331 0.11546ZM2.01976 10.2675C2.11739 10.3651 2.27568 10.3651 2.37331 10.2675L3.9643 8.67651C4.06193 8.57888 4.06193 8.42059 3.9643 8.32296C3.86667 8.22533 3.70838 8.22533 3.61075 8.32296L2.19653 9.73717L0.782319 8.32296C0.684688 8.22533 0.526397 8.22533 0.428766 8.32296C0.331135 8.42059 0.331135 8.57888 0.428766 8.67651L2.01976 10.2675ZM1.94653 0.292236L1.94653 10.0907L2.44653 10.0907L2.44653 0.292236L1.94653 0.292236Z"
                          fill="white"
                        ></path>
                      </svg>
                      <p>M</p>
                    </div>
                    <div className="">
                      <img
                        // src={require("../../assets/tool/avatar/men/styles/hair/thumb/type_1.png")}
                        src={item?.my_nft?.ipfsimage}
                        alt="Collection"
                        className="img-fluid"
                      />
                    </div>
                    <div className="avatar_shadow"></div>
                    <div className="collection_avatar_name"></div>
                  </div>
                </div>
              );
            })}

        
        </div>
      </Scrollbars>
    </div>
  );
}
