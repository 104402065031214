let key = {};


let toasterOption = {
  position: "top-right",
  autoClose: 1000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined
};

let Envname = "demo";
if (Envname == "demo") {
  key = {
    networkId: 97,
    chainId: "97",
    baseUrl: "https://sandboxnftapi.wealwindemo.com/api/",
    imgUrl: "https://sandboxnftapi.wealwindemo.com/",
    myAvatar: "https://sandboxnftapi.wealwindemo.com/default/default.glb",
    rpcUrl: "https://bsc-testnet-rpc.publicnode.com",
    multipleContract: "0x3E3fc78Fd27c7116A8378e2221F90EdEc6FAD3A7",
    IPFS_IMG: "https://ipfs.io/ipfs",
    decimalvalues: 1000000000000000000,
    toFixed: 8,
    currencySymbol: "BNB",
    owntokenSymbol: "BNB",
    toasterOption: toasterOption
  };

} else {
  key = {
    networkId: 97,
    chainId: "97",
    baseUrl: "http://localhost:3040/api/",
    imgUrl: "http://localhost:3040/",
    myAvatar: "http://localhost:3040/default/default.glb",
    rpcUrl: "https://bsc-testnet-rpc.publicnode.com",
    multipleContract: "0x3E3fc78Fd27c7116A8378e2221F90EdEc6FAD3A7",
    IPFS_IMG: "https://ipfs.io/ipfs",
    decimalvalues: 1000000000000000000,
    toFixed: 8,
    currencySymbol: "BNB",
    owntokenSymbol: "BNB",
    toasterOption: toasterOption
  };
}



export default key;
