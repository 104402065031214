/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
Author: sirsaugsage (https://sketchfab.com/sirsausage)
License: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
Source: https://sketchfab.com/3d-models/fantasy-game-inn-192bf30a7e28425ab385aef19769d4b0
Title: Fantasy Game Inn
*/

import { useGLTF } from '@react-three/drei'
import { RigidBody } from '@react-three/rapier'

export default function Land1(props) {
  const { nodes, materials } = useGLTF(require("../assets/tool/land/land.glb"))

  return (
    <RigidBody type="fixed" colliders="trimesh" ccd>
      <group {...props} dispose={null}>
      <group position={[0,-20,0]}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor.geometry}
        material={materials.MI_ProcGrid_SM_MERGED_Floor}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_1.geometry}
        material={materials.T_Mossy_Grass_vcpoajjs_2K_D_Mat}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_2.geometry}
        material={materials.M_Atlas_2}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_3.geometry}
        material={materials.M_Atlas}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_4.geometry}
        material={materials.M_Atlas_4}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_5.geometry}
        material={materials.M_Atlas_3}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_6.geometry}
        material={materials.M_Grass_4}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.SM_MERGED_Floor_7.geometry}
        material={materials.M_Grass_1}
      />
      </group>
      </group>
    </RigidBody>
  )
}

useGLTF.preload(require("../assets/tool/land/land.glb"))
