import { Suspense } from "react";

//COMPONENTS
import Model from "../components/Model";
import Loader from "../Loader";

export default function ViewerScene() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Model />
      </Suspense>
    </>
  );
}
