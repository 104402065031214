//REACT
import { useEffect } from "react";
//R3F
import { extend } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";


//
import glowModel from "../../assets/tool/avatar/men/styles/shirt/type_1.glb";



export default function Model() {
   const { scene } = useGLTF(glowModel);

  useEffect(() => {
    console.log("load stage glow");
    if (scene) {
      //TONE MAPPING - FALSE
      scene.children.forEach((mesh, i) => {
        if (mesh && mesh.isMesh) {
          mesh.material.toneMapped = false;
        }
      });
    }
  }, [scene]);

  return (
    <>
      {scene ? (
        <primitive name={"modelViewer"} object={scene} scale={[60, 60, 60]} position={[0, -50, 0]} />
      ) : null}
    </>
  );
}
