//REACT
import { Suspense } from "react";
import { useSelector } from "react-redux";
//R3F
import { Canvas } from "@react-three/fiber";
import { Environment } from "@react-three/drei";
import * as THREE from "three";
//AVATAR COMPONENTS
import AvatarScene from "./components/AvatarScene";
//SCENE COMPONENTS
import Scene from "./Scene";
import Lightings from "./Lightings";
import PerspectiveCam from "./PerspectiveCam";
import OrbitCtrls from "./OrbitCtrl";
//
import Loader from "./Loader";
import MyAvatar from "./components/MyAvatar";


function LoadEnv() {
    //STATE
    const { settings,myAvatar } = useSelector((state) => state.avatarTool);
  return (
    //pixelRatio={window.devicePixelRatio}
    //gl={{ antialias: false }}
    //toneMappingExposure={12}
  
    
    <Canvas
      gl={{ antialias: true, powerPreference: "high-performance" }}
      outputcolorspace={THREE.SRGBColorSpace}
      tonemapping={THREE.LinearToneMapping}
      shadows
    >
      <Suspense fallback={null}>
        {/* ENVIRONMENT */}
        <Scene />
        <Lightings />
        <PerspectiveCam />
        <OrbitCtrls />
        {/* AVATAR SCENE */}
       
        <AvatarScene />
       
      </Suspense>
    </Canvas>
 
  );
}

export default LoadEnv;
