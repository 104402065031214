import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { Button } from "@mui/material";
import NavbarAuth from "../components/NavbarAuth";
import { Col, Row } from "react-bootstrap";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function ForgotPassword(props) {
  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <Row>
            <Col lg={8} xl={6} className="m-auto">
              <div className="auth_main">
                <form className="auth_form">
                  <div className="mb-5">
                    <h2>Forgot Password</h2>
                    <h5>We'll send a link to your account email.</h5>
                  </div>
                  <div className="form-group">
                    <label className="mb-2">Enter Your Registered Email Address</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Address"
                    />
                  </div>
                  <div className="text-center mt-3">
                    <Button variant="contained" className="primary_btn">
                      Submit
                    </Button>
                  </div>
                  <p className="register_here">
                    Remember your passowrd? <Link to="/login">Sign In</Link>
                  </p>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
