import { useRef } from "react";
import { useSelector } from "react-redux";
//R3F
import { useGLTF } from "@react-three/drei";
//COMPONENTS
import MyAvatarModel from "./MyAvatarModel";

export default function MyAvatar({ modelPath }) {
  //STATE
  const { settings } = useSelector((state) => state.avatarTool);
  const group = useRef();
  const { scene,nodes } = useGLTF(modelPath);

  return (
    <>
      <MyAvatarModel group={group} settings={settings} scene={scene} nodes={nodes}/>
    </>
  );
}
