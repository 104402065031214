//REACT
import { Suspense } from "react";
//R3F
import { Canvas } from "@react-three/fiber";
import { Environment } from "@react-three/drei";
import * as THREE from "three";
//VIEWER COMPONENTS
import ViewerScene from "./components/ViewerScene";
//SCENE COMPONENTS
import Scene from "./Scene";
import Lightings from "./Lightings";
import PerspectiveCam from "./PerspectiveCam";
import OrbitCtrls from "./OrbitCtrl";
//
import Loader from "./Loader";

function LoadEnv() {
  return (
    <Canvas
      gl={{ antialias: true, powerPreference: "high-performance" }}
      outputcolorspace={THREE.SRGBColorSpace}
      tonemapping={THREE.LinearToneMapping}
      shadows
    >
      <Suspense fallback={null}>
        {/* ENVIRONMENT */}
        <Scene />
        <Lightings />
        <PerspectiveCam />
        <OrbitCtrls />
        {/* VIEWER SCENE */}
        <ViewerScene />
      </Suspense>
    </Canvas>
 
  );
}

export default LoadEnv;
