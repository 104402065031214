//REACT
import { useEffect } from "react";
//R3F
import { extend } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import {
  EffectComposer,
  Bloom,
  ToneMapping,
} from "@react-three/postprocessing";
import { ToneMappingMode } from "postprocessing";
import { UnrealBloomPass } from "three-stdlib";
import { OutputPass } from "three/examples/jsm/postprocessing/OutputPass";

//
import glowModel from "../../assets/tool/avatar/stage_glow.glb";

extend({ UnrealBloomPass, OutputPass });

export default function AvatarStageGlow() {
  const { scene } = useGLTF(glowModel);

  useEffect(() => {
    //console.log("load stage glow");
    if (scene) {
      //TONE MAPPING - FALSE
      scene.children.forEach((mesh, i) => {
        if (mesh && mesh.isMesh) {
          mesh.material.toneMapped = false;
        }
      });
    }
  }, [scene]);

  return (
    <>
      {/* <EffectComposer>
        <Bloom mipmapBlur luminanceThreshold={0.8} levels={8} intensity={1} />
        <ToneMapping mode={ToneMappingMode.LINEAR} />
      </EffectComposer> */}
      {scene ? (
        <primitive name={"stageGlow"} object={scene} scale={[60, 60, 60]} position={[0, -50, 0]} />
      ) : null}
    </>
  );
}
