import React, { useState,useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";
import ConnectWallet from "./ConnectWallet";
import { useWalletClient, useNetwork, useDisconnect } from "wagmi";
import { walletClientToSigner } from "../helper/getprovider";
import Web3 from "web3";
import { userSaveAction } from "../apiAction/nfts";
import config from "../config/index";

import { setWallet } from "../redux/action/action";
import { useDispatch, useSelector } from "react-redux";

export default function Navbar(props) {
  const dispatch = useDispatch();
  const { data: walletClient } = useWalletClient({ chainId: config.chainId });
  const { chain } = useNetwork();
  const timerRef = useRef(null);


  const [userAddress, setuseraddress] = useState();

  const walletConnection = useSelector((state) => state.walletConnection);

  const { disconnect } = useDisconnect();

  console.log(
    userAddress,
    "userAddressuserAddressuserAddress",
    walletConnection
  );
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".app_header");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      30 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      30 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      30 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    // Sidebar Add Class
    $(document).ready(function () {
      $(".topnavbar_right i").click(function () {
        $(".app_sidebar").addClass("show");
        $("body").addClass("menu_overlay");
      });
    });
  }

  useEffect(() => {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", function (accounts) {
        console.log(accounts, "accountsef");
        window.location.reload();
      });
    }
  }, []);

  useEffect(() => {
    setConnection();
    // eslint-disable-next-line
  }, [walletClient, chain]);

  async function setConnection() {
    // setisUser(false);

    console.log(walletClient, chain, "walletClient, chain");
    if (walletClient && chain && parseInt(chain.id) == config.chainId) {
      var { transport } = walletClientToSigner(walletClient);
      var web3 = new Web3(transport);
      var userAddr =
        walletClient && walletClient.account && walletClient.account.address
          ? walletClient.account.address
          : "";
      var chainVal = chain && chain.id ? chain.id : config.chainId;

      var getBalance = await web3.eth.getBalance(userAddr);
      getBalance = parseFloat(getBalance);
      getBalance = getBalance / 10 ** 18;

      console.log(
        parseInt(chainVal),
        web3,
        userAddr,
        transport,
        "yes",
        getBalance,
        "sssssss"
      );

      dispatch(
        setWallet({
          network: parseInt(chainVal),
          web3: web3,
          address: userAddr,
          provider: transport,
          connect: "yes",
          balance: getBalance,
        })
      );
      const payload = {
        address: userAddr,
      };
      const userSave = await userSaveAction(payload);
      setuseraddress(userAddr);
    } else {
      var chainVal1 =
        localStorage.getItem("network") &&
        parseInt(localStorage.getItem("network")) > 0
          ? parseInt(localStorage.getItem("network"))
          : config.chainId;
      var web3 = new Web3(config.rpcUrl);
      dispatch(
        setWallet({
          network: chainVal1,
          web3: web3,
          address: "",
          provider: "",
          connect: "no",
          balance: 0,
        })
      );
    }
  }



  window.addEventListener("load", async (event) => {
    // if (localStorage.getItem("account")) {
    event.preventDefault();
    var provider = window.ethereum;
    var web3 = new Web3(provider);


    if (window.ethereum) {
      window.ethereum.on("accountsChanged", async function (accounts) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(async () => {
          var result = await web3.eth.getAccounts();
          var setacc = result[0];
          localStorage.setItem("account", result[0]);
          window.location.href = "/";
        }, 1000);
      });

      window.ethereum.on("networkChanged", async function (networkId) {
        var provider = window.ethereum;
        var web3 = new Web3(provider);
        if (networkId == config.NetworkId) {
          if (timerRef.current) {
            clearTimeout(timerRef.current);
          }
          timerRef.current = setTimeout(async () => {
            var result = await web3.eth.getAccounts();
            var setacc = result[0];

            localStorage.setItem("account", result[0]);
            window.location.href = "/";
          }, 1000);
        } else {
          localStorage.clear();
          window.location.href = "/";
        }
      });
    }
  });





  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-dark app_header">
        <div className="container-fluid">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Logo" className="img-fluid brand_logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>

          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>

            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1">
                {walletConnection.connect == "yes" ? (
                  <li className="nav-item dropdown usericon_dropdown d-none d-xl-block">
                    <a
                      href="#"
                      className="nav-link"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={require("../assets/images/user_img.png")}
                        alt="User"
                        width="32"
                        height="32"
                        className="rounded-circle"
                      />
                    </a>
                    <ul className="dropdown-menu dropdown-menu-dark">
                      <li>
                        <Link className="dropdown-item" to="/account-details">
                          {walletConnection.address}
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/account-details">
                          {walletConnection.balance} BNB
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/account-details">
                          Account Details
                        </Link>
                      </li>

                      <li>
                        <Link
                          className="dropdown-item"
                          to="/"
                          onClick={disconnect}
                        >
                          Logout
                        </Link>
                      </li>
                    </ul>
                  </li>
                ) : (
                  <div
                    className="dark mem"
                    data-bs-toggle="modal"
                    data-bs-target="#connect_wallet_modal"
                    style={{ cursor: "pointer", color: "#fff" }}
                  >
                    CONNECT WALLET
                  </div>
                )}

                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/screens">
                    Map
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/media">
                    Events
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/playlists">
                    Experiences
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/groups">
                    Avatar
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/help">
                    Marketplace
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/account-details">
                    Create
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/account-details">
                    Partnerships
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/account-details">
                    News
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/account-details">
                    About
                  </Link>
                </li>
                <li className="nav-item d-block d-xl-none">
                  <Link className="nav-link" to="/">
                    Logout
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>

      <ConnectWallet />
    </>
  );
}
