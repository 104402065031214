import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import { Button } from "@mui/material";
import NavbarAuth from "../components/NavbarAuth";
import { Col, Row } from "react-bootstrap";

// Scroll to Top
function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return null;
}

export default function Login(props) {
  return (
    <>
      <ScrollToTopOnMount />
      <NavbarAuth />
      <div className="auth_wrapper">
        <div className="container">
          <Row>
            <Col lg={8} xl={6} className="m-auto">
              <div className="auth_main">
                <form className="auth_form">
                  <div className="mb-5">
                    <h2>
                      Welcome back to the metaverse
                    </h2>
                    <h5>Sign In to Continue!</h5>
                  </div>
                  <div className="form-group">
                    <label className="mb-2">Email Address</label>
                    <input
                      className="form-control"
                      type="email"
                      placeholder="Enter Email Address"
                    />
                  </div>
                  <div className="form-group">
                    <label className="mb-2">Password</label>
                    <input
                      className="form-control"
                      type="password"
                      placeholder="Enter Password"
                    />
                  </div>
                  <div className="auth_footer">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Remember me
                      </label>
                    </div>
                    <Link to="/forgot-password">Forgot your password?</Link>
                  </div>
                  <div className="text-center mt-3">
                    <Button variant="contained" className="primary_btn">Login</Button>
                  </div>
                  <p className="register_here">
                    Don't have an account?{" "}
                    <Link to="/register">Register Here</Link>
                  </p>
                </form>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}
