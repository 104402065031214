import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../assets/images/logo.svg";
import $ from "jquery";

export default function NavbarAuth(props) {
  useEffect(() => {
    loadScript();
  }, []);

  function loadScript() {
    // Navbar Sticky
    var t = $(".app_header");
    $(window).on("scroll load", function (e) {
      var e = $(window).scrollTop();
      30 <= e
        ? t.addClass("navbar-sticky--moved-up")
        : t.removeClass("navbar-sticky--moved-up");
      30 <= e
        ? t.addClass("navbar-sticky--transitioned")
        : t.removeClass("navbar-sticky--transitioned");
      30 <= e
        ? t.addClass("navbar-sticky--on")
        : t.removeClass("navbar-sticky--on");
    });

    // Sidebar Add Class
    $(document).ready(function () {
      $(".topnavbar_right i").click(function () {
        $(".app_sidebar").addClass("show");
        $("body").addClass("menu_overlay");
      });
    });
  }

  return (
    <>
      <nav className="navbar navbar-expand-xl navbar-dark auth_header">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="Logo" className="img-fluid brand_logo" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasNavbar"
            aria-controls="offcanvasNavbar"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className="offcanvas offcanvas-end"
            tabindex="-1"
            id="offcanvasNavbar"
            aria-labelledby="offcanvasNavbarLabel"
          >
            <div className="offcanvas-header">
              <h5 className="offcanvas-title" id="offcanvasNavbarLabel"></h5>
              <button
                type="button"
                className="btn-close text-reset"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
              ></button>
            </div>
            <div className="offcanvas-body">
              <ul className="navbar-nav justify-content-end flex-grow-1">
                <li className="nav-item">
                  <Link className="nav-link" to="/login">
                    Login
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/register">
                    Register
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
}
