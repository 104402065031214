import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
//R3F
import { useThree } from "@react-three/fiber";
import { gsap } from "gsap";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";

//POSITIONING
import AvatarPan from "../config/AvatarPan.json";
//STATE ACTIONS
import { setPanning } from "../../redux/action/toolpage/action";

export default function AvatarPanning() {
  const { ui } = useSelector((state) => state.avatarTool);
  const camera = useThree((state) => state.camera);
  const controls = useThree((state) => state.controls);
  const scene = useThree((state) => state.scene);
  const { gl } = useThree();
  const dispatch = useDispatch();

  useEffect(() => {
    const x = AvatarPan?.[ui.option]?.position?.x || 0,
      y = AvatarPan?.[ui.option]?.position?.y || 0,
      z = AvatarPan?.[ui.option]?.position?.z || 0,
      zoomLevel = AvatarPan?.[ui.option]?.zoom?.level || 1;

    focus(x, y, z, zoomLevel);
  }, [ui?.option]);

  useEffect(() => {
    if (ui?.download !== false) {
      //handleDownload();
      download();
    }
  }, [ui?.download]);

  const focus = (x, y, z, zoomLevel) => {
    gsap
      .timeline({ defaults: { duration: 1.5, ease: "expo.out" } })
      .to(controls?.target, { x, y, z })
      .to(camera, {
        duration: 2,
        zoom: zoomLevel,
        onUpdate: function () {
          camera.updateProjectionMatrix();
        },
      });
  };

  function download() {
    const exporter = new GLTFExporter();

    const filename = "avatar";
    exporter.parse(scene?.children[8], (result) => {
      async function Save(blob, filename) {
        const link = document.createElement("a");

        link.style.display = "none"; // u/ts-ignore

        document.body.appendChild(link); // Firefox workaround, see #6594

        link.href = URL.createObjectURL(blob);

        link.download = filename;

        link.click(); // URL.revokeObjectURL( url ); breaks Firefox... // Just downloading, not uploading
      }
      if (result instanceof ArrayBuffer)
        sendFileToBackend(
          new Blob([result], { type: "application/octet-stream" }),
          "./" + filename + ".glb"
        );
      else
        sendFileToBackend(
          new Blob([JSON.stringify(result, null, 2)], { type: "text/plain" }),
          "./" + filename + ".gltf"
        );
    });
  }

  function sendFileToBackend(blob, filename) {
    let sceneFile = new File([blob], "avatar.glb");

    //screenshot
    gl.render(scene, camera);
    const screenshotBlob = base64ToBlob(gl.domElement.toDataURL(), "png");
    const screenshotFile = new File([screenshotBlob], "screenshot.png");

    //STATE CHANGES
    let Details = {
      info: ui,
    };
    Details.info.avatarModel = sceneFile;
    Details.info.avatarImage = screenshotFile;
    Details.info.download = false;
    dispatch(setPanning(Details));
  }

  function base64ToBlob(base64String, mimeType) {
    // Split the base64 string into two parts
    const byteCharacters = atob(base64String.split(",")[1]);

    // Turn the string of byte characters into a typed array
    const byteArray = new Uint8Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteArray[i] = byteCharacters.charCodeAt(i);
    }

    // Create a Blob object from the typed array
    const blob = new Blob([byteArray], { type: mimeType });
    return blob;
  }
}
