import { Canvas } from "@react-three/fiber";
import { Physics } from "@react-three/rapier";
import { Environment, KeyboardControls } from "@react-three/drei";
import { Suspense } from "react";
import Ecctrl, { EcctrlAnimation, EcctrlJoystick } from "ecctrl";

import Lights from "./Lights";
import Land1 from "./Land1";
import CharacterModel from "./CharacterModel";


export default function Scene() {
  /**
   * Keyboard control preset
   */
  const keyboardMap = [
    { name: "forward", keys: ["ArrowUp", "KeyW"] },
    { name: "backward", keys: ["ArrowDown", "KeyS"] },
    { name: "leftward", keys: ["ArrowLeft", "KeyA"] },
    { name: "rightward", keys: ["ArrowRight", "KeyD"] },
    { name: "jump", keys: ["Space"] },
    { name: "run", keys: ["Shift"] },
    { name: "action1", keys: ["1"] },
    { name: "action2", keys: ["2"] },
    { name: "action3", keys: ["3"] },
    { name: "action4", keys: ["KeyF"] },
  ];

  /**
   * Character url preset
   */
  const characterURL = require("../assets/tool/land/package.glb");

  const animationSet = {
    idle: "Armature|mixamo.com|Layer0",
    walk: "Armature|mixamo.com|Layer0.007",
    run: "Armature|mixamo.com|Layer0.001",
    jump: "Armature|mixamo.com|Layer0.002",
    jumpIdle: "Armature|mixamo.com|Layer0.002",
    jumpLand: "Armature|mixamo.com|Layer0.002",
    fall: "Armature|mixamo.com|Layer0", // This is for falling from high sky
    //  action1: "Jump",
    //  action2: "Jump",
    //  action3: "Jump",
    //  action4: "Jump"
  };

  return (
    <>
      <Canvas
        shadows
        // onPointerDown={(e) => {
        //   if (e.pointerType === "mouse") {
        //     e.target.requestPointerLock();
        //   }
        // }}
      >
        <Environment background files={require("../assets/tool/land/night.hdr")} />
        <Lights />
        <Suspense fallback={null}>
          <Physics timeStep="vary">
            <KeyboardControls map={keyboardMap}>
              <Ecctrl animated maxVelLimit={7}>
                <EcctrlAnimation
                  characterURL={characterURL}
                  animationSet={animationSet}
                >
                  <CharacterModel />
                </EcctrlAnimation>
              </Ecctrl>
            </KeyboardControls>
            <Land1 />
          </Physics>
        </Suspense>
      </Canvas>
    </>
  );
}
