//REACT
import React, { useRef, useMemo } from "react";
import { useSelector } from "react-redux";
//R3F
import { useGraph } from "@react-three/fiber";
import { useGLTF } from "@react-three/drei";
import * as SkeletonUtils from "three/examples/jsm/utils/SkeletonUtils";
//COMPONENTS
import AvatarModel from "./AvatarModel";
//CUSTOM STYLE
import CustomAvatar from "../config/CustomAvatar.json";


export default function Avatar() {
  //STATE
  const { settings, men } = useSelector((state) => state.avatarTool);

  //BASE MODEL
  const group = useRef();
  const { scene, materials } = useGLTF(require("../../assets/tool/avatar/"+ settings?.model +"/base.glb"));
  const clone = useMemo(() => SkeletonUtils.clone(scene), [scene]);
  const { nodes } = useGraph(clone);

  //console.log("nodes:=", nodes);

  //CUSTOM STYLE
  //let skeleton = model.nodes[CustomAvatar?.[settings?.model]?.[men?.option]?.nodeName].skeleton;
  //HAIR
  const hairModel = useGLTF(require("../../assets/tool/avatar/" + settings?.model + '/styles/hair/' + men?.hair?.type + ".glb"));
  let hair_geo = hairModel.nodes[CustomAvatar?.[settings?.model]?.hair?.[men?.hair?.type]?.nodeName]?.geometry;
  let hair_mat = hairModel.nodes[CustomAvatar?.[settings?.model]?.hair?.[men?.hair?.type]?.nodeName]?.material;
  hair_mat.color.setHex(men?.hair?.color); 


  //SHIRT
  const shirtModel = useGLTF(require("../../assets/tool/avatar/" + settings?.model + "/styles/shirt/"+ men?.shirt?.type + ".glb"));
  let leftHand_geo = shirtModel.nodes[CustomAvatar?.[settings?.model]?.shirt?.[men?.shirt?.type]?.leftHand?.nodeName]?.geometry;
  let leftHand_mat = shirtModel.nodes[CustomAvatar?.[settings?.model]?.shirt?.[men?.shirt?.type]?.leftHand?.nodeName]?.material;

  let rightHand_geo = shirtModel.nodes[CustomAvatar?.[settings?.model]?.shirt?.[men?.shirt?.type]?.rightHand?.nodeName]?.geometry;
  let rightHand_mat = shirtModel.nodes[CustomAvatar?.[settings?.model]?.shirt?.[men?.shirt?.type]?.rightHand?.nodeName]?.material;

  let shirtMesh=new Array();
  CustomAvatar?.[settings?.model]?.shirt?.[men?.shirt?.type]?.shirt.forEach((details) => {
    let geoMat={
      geo : shirtModel.nodes[details?.nodeName]?.geometry,
      mat : shirtModel.nodes[details?.nodeName]?.material
    };
    shirtMesh.push(geoMat);
  });

  let jacketMesh=new Array();
  CustomAvatar?.[settings?.model]?.shirt?.[men?.shirt?.type]?.jacket.forEach((details) => {
    let geoMat={
      geo : shirtModel.nodes[details?.nodeName]?.geometry,
      mat : shirtModel.nodes[details?.nodeName]?.material
    };
    jacketMesh.push(geoMat);
  });
 
  //PANT
  const pantModel = useGLTF(require("../../assets/tool/avatar/" + settings?.model + "/styles/pant/"+ men?.pant?.type + ".glb"));
  let leg_geo = pantModel.nodes[CustomAvatar?.[settings?.model]?.pant?.[men?.pant?.type]?.leg?.nodeName]?.geometry;
  let leg_mat = pantModel.nodes[CustomAvatar?.[settings?.model]?.pant?.[men?.pant?.type]?.leg?.nodeName]?.material;

  let pantMesh=new Array();
  CustomAvatar?.[settings?.model]?.pant?.[men?.pant?.type]?.pant.forEach((details) => {
    let geoMat={
      geo : pantModel.nodes[details?.nodeName]?.geometry,
      mat : pantModel.nodes[details?.nodeName]?.material
    };
    pantMesh.push(geoMat);
  });

  //Shoe
  const shoeModel = useGLTF(require("../../assets/tool/avatar/" + settings?.model + "/styles/shoe/"+ men?.shoe?.type + ".glb"));
  //console.log("shoemodel:=",shoeModel);
  let shoeMesh=new Array();
  CustomAvatar?.[settings?.model]?.shoe?.[men?.shoe?.type]?.shoe.forEach((details) => {
    let geoMat={
      geo : shoeModel.nodes[details?.nodeName]?.geometry,
      mat : shoeModel.nodes[details?.nodeName]?.material
    };
    shoeMesh.push(geoMat);
  });

  return (
    <>
      <AvatarModel
        group={group}
        materials={materials}
        nodes={nodes}
        settings={settings}
        menInfo={{
            option : men?.option,
            hair:{
              geo : hair_geo,
              mat : hair_mat
            },
            shirt:{
              leftHand:{
                geo : leftHand_geo,
                mat : leftHand_mat
              },
              rightHand:{
                geo : rightHand_geo,
                mat : rightHand_mat
              },
              shirtMesh : shirtMesh,
              jacketMesh : jacketMesh
            },
            pant:{
              leg:{
                geo : leg_geo,
                mat : leg_mat
              },
              pantMesh : pantMesh
            },
            shoe:{
              shoeMesh : shoeMesh
            }
        }}
      />
    </>
  );
}
