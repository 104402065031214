import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import config from "../config/index";
import {
  TokenAddItemAction,
  TokenAddOwnerAction
} from "../apiAction/nfts.js";
import NFT1155 from "../ABI/NFT1155.json";
import Navbar from "../components/Navbar.js";
import Sidebar from "../components/Sidebar.js";

export default function SampleCreate() {
  const walletConnection = useSelector((state) => state.walletConnection);

  function ScrollToTopOnMount() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, []);
    return null;
  }

  const handleMintSmaple = async () => {
    try {
      console.log(walletConnection);
      if (walletConnection.address) {
        // return
        console.log("Check");
        let web3 = walletConnection.web3;
        var multiNFTContract = new web3.eth.Contract(
          NFT1155,
          config.multipleContract
        );
        var unix = Math.round(new Date() / 1000);
        let ran = Math.floor(Math.random() * 100 + 1);
        let tokenid = unix + ran;
        let nftName = "NFT" + tokenid;

        let Price = 0.001 * 1e18;
        let TokenPriceInStr = Price.toString();
        let TokenQuantity = 10;
        let ipfs_url =
          "https://aqua-back-python-620.mypinata.cloud/ipfs/QmbxBfFyCKjaHKzLPGmxF3ZakFVN4KnBNZZXQSuBh1aaUH/103.png";

        let ipfs_hash = "QmbxBfFyCKjaHKzLPGmxF3ZakFVN4KnBNZZXQSuBh1aaUH/103.png";


        await multiNFTContract.methods
          .mint(
            tokenid,
            TokenPriceInStr,
            "5",
            TokenQuantity.toString(),
            nftName,
            ipfs_hash,
            ipfs_url
          )
          .send({ from: walletConnection.address })
          .then(async (result) => {
            var transactionHash = "";
            if (result.transactionHash) {
              transactionHash = result.transactionHash;
            } else {
              transactionHash = result;
            }
            var TokenAddItemPayload = {
              Image: "",
              ipfsimage: ipfs_url,
              Name: nftName,
              Count: tokenid,
              Description: "TokenDescription",
              Price: 0.001,
              TokenPrice: 0,
              Royalities: 5,
              Category_label: "Music",
              Owner: walletConnection.address,
              Creator: walletConnection.address,
              Quantity: TokenQuantity,
              Balance: TokenQuantity,
              ContractAddress: config.multipleContract,
              Status: true,
              HashValue: transactionHash,
              Type: 1155,
              MinimumBid: 0,
              Nft_type:2,  // 1 - accessories 2 -noraml nft 
              MaximumBid: 0,
              Clocktime: "",
              EndClocktime: "",
              UnLockcontent: "",
              PutOnSale: true,
              PutOnSaleType: "FixedPrice",
              currencyName: "BNB"
            };
            await TokenAddItemAction(TokenAddItemPayload);
            var TokenAddOwnerPayload = {
              Count: tokenid,
              Price: 0.001,
              TokenPrice: 0,
              Owner: walletConnection.address,
              Balance: TokenQuantity,
              Quantity: TokenQuantity,
              ContractAddress: config.multipleContract,
              Type: 1155,
              HashValue: transactionHash,
              Status: true,
              currencyName: "BNB"
            };
            await TokenAddOwnerAction(TokenAddOwnerPayload);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <ScrollToTopOnMount />
      <div className="apppage_wrapper">
        <div className="app_page">
          <Navbar />
          <div className="page_wrapper">
            <Sidebar />
            <div className="page_content">
              <button onClick={handleMintSmaple}>ClickToMint</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
