import axios from "axios";
import config from "../config/index.js";

export const TokenAddItemAction = async (payload) => {
  try {
    console.log(payload, "payload");

    // var formData = new FormData();
    // if (payload.Image) {
    //   formData.append("Image", payload.Image);
    // }
    // if (payload.Nft_type) {
    //   formData.append("Nft_type", payload.Nft_type);
    // }
    // if (payload.ipfsimage) {
    //   formData.append("ipfsimage", payload.ipfsimage);
    // }
    // if (payload.Name) {
    //   formData.append("Name", payload.Name);
    // }
    // if (payload.Count) {
    //   formData.append("Count", payload.Count);
    // }
    // if (payload.Description) {
    //   formData.append("Description", payload.Description);
    // }
    // if (payload.Price) {
    //   formData.append("Price", payload.Price);
    // }
    // if (payload.MaximumBid) {
    //   formData.append("MaximumBid", payload.MaximumBid);
    // }
    // if (payload.TokenPrice) {
    //   formData.append("TokenPrice", payload.TokenPrice);
    // }
    // if (payload.Royalities) {
    //   formData.append("Royalities", payload.Royalities);
    // }

    // if (payload.Owner) {
    //   formData.append("Owner", payload.Owner);
    // }
    // if (payload.Creator) {
    //   formData.append("Creator", payload.Creator);
    // }
    // if (payload.CategoryId) {
    //   formData.append("CategoryId", payload.CategoryId);
    // }
    // if (payload.Quantity) {
    //   formData.append("Quantity", payload.Quantity);
    // }
    // if (payload.Balance) {
    //   formData.append("Balance", payload.Balance);
    // }
    // if (payload.ContractAddress) {
    //   formData.append("ContractAddress", payload.ContractAddress);
    // }
    // if (payload.Status) {
    //   formData.append("Status", payload.Status);
    // }
    // if (payload.HashValue) {
    //   formData.append("HashValue", payload.HashValue);
    // }
    // if (payload.Type) {
    //   formData.append("Type", payload.Type);
    // }
    // if (payload.MinimumBid) {
    //   formData.append("MinimumBid", payload.MinimumBid);
    // }
    // if (payload.EndClocktime) {
    //   formData.append("EndClocktime", payload.EndClocktime);
    // }
    // if (payload.Clocktime) {
    //   formData.append("Clocktime", payload.Clocktime);
    // }
    // if (payload.UnLockcontent) {
    //   formData.append("UnLockcontent", payload.UnLockcontent);
    // }
    // if (payload.PutOnSale) {
    //   formData.append("PutOnSale", payload.PutOnSale);
    // }
    // if (payload.PutOnSaleType) {
    //   formData.append("PutOnSaleType", payload.PutOnSaleType);
    // }
    // if (payload.currencyName) {
    //   formData.append("currencyName", payload.currencyName);
    // }

    let respData = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/add-nft`,
      data: payload,
    });
    return { data: respData.data };
  } catch (err) {
    return { error: err };
  }
};

export const TokenAddOwnerAction = async (payload) => {
  try {
    var SendData = {};
    if (payload.Count) {
      SendData.Count = payload.Count;
    }
    if (payload.Price) {
      SendData.Price = payload.Price;
    }
    if (payload.TokenPrice) {
      SendData.TokenPrice = payload.TokenPrice;
    }
    if (payload.Owner) {
      SendData.Owner = payload.Owner;
    }
    if (payload.Balance) {
      SendData.Balance = payload.Balance;
    }
    if (payload.Quantity) {
      SendData.Quantity = payload.Quantity;
    }
    if (payload.ContractAddress) {
      SendData.ContractAddress = payload.ContractAddress;
    }
    if (payload.Type) {
      SendData.Type = payload.Type;
    }
    if (payload.HashValue) {
      SendData.HashValue = payload.HashValue;
    }
    if (payload.biddingtoken) {
      SendData.biddingtoken = payload.biddingtoken;
    }
    if (payload.Status) {
      SendData.Status = payload.Status;
    }
    if (payload.currencyName) {
      SendData.currencyName = payload.currencyName;
    }

    let resp1Data = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/add-owner`,
      data: SendData,
    });
    return { data: resp1Data.data };
  } catch (err) {
    return {
      //errors: err.response.data
    };
  }
};

export const Token_Get_Detials_Action = async (data) => {
  try {
    let resp1Data = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/nfts-get-details`,
      data: data,
    });
    return { data: resp1Data.data };
  } catch (err) {
    return {
      //errors: err.response.data
    };
  }
};
export const TokenCounts_Get_Detail_Action = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/tokenDetails`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};
export const PurchaseNow_Complete_Action = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/purchase-completed`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const TokenPriceChange_update_Action = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/price-change`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {}
};

export const CollectiblesList_Home = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/nft-list`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const userSaveAction = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/user-save`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const get_User_Accessories = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/getUserAccessories`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getCategoryData = async () => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}routesNft/getCategory`,
    });

    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getNftByCat = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}routesNft/getNftByCategory`,
      params: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getNftByCatName = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/getNftByCatName`,
      data: payload,
    });

    console.log(resp, "respresp 111111");
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

//ipfsImageHash
export const ipfsImageHash = async (payload) => {
  try {
    console.log(payload, "payloadpayload");

    var formData = new FormData();
    formData.append("ImageNFT", payload.image);
    formData.append("Imagefile", payload.Imagefile);

    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/ipfsImageHash`,
      data: formData,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

//ipfsImageHash
export const ipfsmetadatafunciton = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/ipfsmetadata`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getUserAvatar = async (payload) => {
  try {
    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/getUserAvatar`,
      data: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const updateDraftNft = async (payload) => {
  try {
    var formData = new FormData();
    formData.append("DraftImage", payload.image);
    formData.append("address", payload.address);
    formData.append("customAvatar", payload.customAvatar);

    let resp = await axios({
      method: "post",
      url: `${config.baseUrl}routesNft/updateDraftNft`,
      data: formData,
    });

    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};

export const getUserDraft = async (payload) => {
  try {
    let resp = await axios({
      method: "get",
      url: `${config.baseUrl}routesNft/getUserDraft`,
      params: payload,
    });
    return {
      data: resp.data,
    };
  } catch (err) {
    console.log(err);
  }
};
